<template>
  <LayoutContainer
    :headerBackground="require('@/assets/images/theme/header-project.png')"
  >
    <template slot="headerLeft">
      <search-project ref="searchProject" @select="onSelect" />
    </template>
    <div :class="$style.tab_container" slot="headerMiddle">
      <!-- <span
        @mousedown="scrollLeft"
        @mouseup="stopScroll"
        @mouseleave="stopScroll"
        :class="$style.left_arrow"
        v-if="tabList.length > 7"
        ><a-icon type="left"
      /></span>
      <span
        @mousedown="scrollRight"
        @mouseup="stopScroll"
        @mouseleave="stopScroll"
        :class="$style.right_arrow"
        v-if="tabList.length > 7"
        ><a-icon type="right"
      /></span> -->
      <div :class="$style.tab" ref="scrollbar" class="screen_scrollbar">
        <div :class="$style.x_scroll">
          <div
            v-for="item in tabList"
            :key="item.menuId"
            :class="[
              $style.infoTab,
              item.menuId === presentRouter ? $style.activeTab : '',
            ]"
            :title="item.text"
            @click="changeHeaderTab(item)"
          >
            <div :class="$style.navText">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.content">
      <div :class="$style.childrenMenu" v-if="childrenTabList.length">
        <div
          v-for="item in childrenTabList"
          :key="item.menuId"
          @click="changeTab(item)"
          :class="[
            $style.childrenTab,
            item.menuId === activeTab ? $style.activeTab : '',
          ]"
        >
          {{ item.text }}
        </div>
      </div>
      <div :class="$style.main">
        <router-view
          v-if="isRouterAlive"
          :url="url"
          :projectName="projectName"
        />
      </div>
    </div>
  </LayoutContainer>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon';
import { LayoutContainer } from '../layout';
import { fetchPermission } from '@/services/production/module-auth';
import SearchProject from './search-project.vue';
import { templateEnum } from '@/enum/screen';
import store from '@/store';
import { getLocationParams } from '@/views/screen/utils/index';

@Component({
  components: {
    AliIcon,
    LayoutContainer,
    SearchProject,
  },
})
export default class ProjectIndex extends Vue {
  async mounted() {
    await this.initMenu();
    this.toProject();
    document.documentElement.classList = [];
    document.documentElement.classList.add('screen-theme', 'theme_one');
  }
  // 跳转到指定项目(为了省平台验证加的)
  async toProject() {
    if (sessionStorage['PROJECT_ID']) {
      const id = sessionStorage['PROJECT_ID'];
      const obj = this.$refs.searchProject.keyList.find(
        item => item.key === id,
      );
      if (obj) {
        this.$store.commit('screen/updateProjectId', id);
        this.$refs.searchProject.projectName = obj.title;
        const params = {
          groupId: obj.groupId,
          configType: obj.type,
          projectName: obj.title,
        };
        await this.onSelect(params);
        sessionStorage.removeItem('PROJECT_ID');
      }
    }
  }
  get baseUrl() {
    // return this.$store.state.screen.token ? '/screen/share' : '/project';
    return sessionStorage['SHARE_TOKEN'] ? '/screen/share' : '/project';
  }
  /** 初始化菜单 */
  async initMenu() {
    await this.getMenuList({
      groupId: this.$refs.searchProject.groupId,
      configType: '1',
    });
  }

  /** 项目筛选 */
  projectName = '';
  configType = '1';
  async onSelect(params) {
    const { groupId, configType, projectName } = params;
    this.projectName = projectName;
    await this.getMenuList({ groupId, configType });
    if (this.configType === configType) {
      this.reload();
    }
    this.configType = configType;
  }

  /** 主菜单 */
  tabList = [];
  presentRouter = ''; // 当前主菜单路由
  async getMenuList(params) {
    await this.$store.dispatch('screen/updateScreenMenus', params);
    // 判断是否有菜单权限
    if (!this.$store.state.screen.screenMenus.length) {
      this.tabList = [];
      const pathArr = this.$route.fullPath.split('/');
      if (pathArr[pathArr.length - 1] !== '403') {
        this.$router.replace({
          path: `${this.baseUrl}/403`,
        });
      }
      return;
    }
    this.tabList = this.$store.state.screen.screenMenus.map(item => ({
      text: item.menuName,
      menuId: item.menuId,
      path:
        item.menuType === 1 ? templateEnum[item.templateCode] : 'custom-link',
      menuType: item.menuType, // 1/2 正常路由/自定义链接
      url: item.url, // 自定义链接
      children: item.children,
    }));
    // 判断第一个主菜单是否为自定义链接并给url赋值
    if (this.tabList[0].menuType === 2) {
      this.url = this.tabList[0].url;
    }
    // 判断是否有子菜单，如果有子菜单就跳转到第一个子菜单
    if (this.tabList[0].children.length) {
      this.childrenTabList = this.tabList[0].children.map(childItem => ({
        text: childItem.menuName,
        menuId: childItem.menuId,
        menuType: childItem.menuType,
        url: childItem.url,
        path:
          childItem.menuType === 1
            ? templateEnum[childItem.templateCode]
            : 'custom-link',
      }));
      // 判断第一个子菜单是否为自定义链接并给url赋值
      if (this.childrenTabList[0].menuType === 2) {
        this.url = this.childrenTabList[0].url;
      }
      // 跳转到第一个子菜单并且选中第一个子菜单
      this.activeTab = this.childrenTabList[0].menuId;
      this.$router.push({
        path: `${this.baseUrl}/${this.childrenTabList[0].path}`,
      });
    } else {
      this.childrenTabList = [];
      this.$router.push({ path: `${this.baseUrl}/${this.tabList[0].path}` });
    }
    this.presentRouter = this.tabList[0].menuId;
  }
  url = ''; // 自定义页面的URL
  changeHeaderTab(item) {
    if (item.menuType === 2) {
      this.url = item.url;
    }
    this.childrenTabList = [];
    if (item.children.length) {
      this.childrenTabList = item.children.map(childItem => ({
        menuId: childItem.menuId,
        text: childItem.menuName,
        menuType: childItem.menuType,
        url: childItem.url,
        path:
          childItem.menuType === 1
            ? templateEnum[childItem.templateCode]
            : 'custom-link',
      }));
      // 判断第一个子菜单是否为自定义链接并给url赋值
      if (this.childrenTabList[0].menuType === 2) {
        this.url = this.childrenTabList[0].url;
      }
      this.presentRouter = item.menuId;
      this.$router.push({
        path: `${this.baseUrl}/${this.childrenTabList[0].path}`,
      });
      // 切换主菜单时重新赋值，保证有子菜单的路由可以默认选中第一个子菜单
      this.$nextTick(() => {
        this.activeTab = this.childrenTabList[0].menuId;
      });
    } else {
      this.presentRouter = item.menuId;
      this.$router.push({ path: `${this.baseUrl}/${item.path}` });
    }
  }

  /** 子菜单 */
  childrenTabList = [];
  activeTab = ''; // 当前子菜单路由
  changeTab(item) {
    if (item.menuType === 2) {
      this.url = item.url;
    }
    if (this.activeTab !== item.menuId) {
      this.activeTab = item.menuId;
      this.$router.push({
        path: `${this.baseUrl}/${item.path}`,
      });
    }
  }

  /** 重载页面 */
  isRouterAlive = true;
  reload() {
    this.isRouterAlive = false;
    this.$nextTick(() => {
      this.isRouterAlive = true;
    });
  }

  /** 路由拦截 */
  async beforeRouteEnter(to, from, next) {
    // 进入大屏页面前将url拼接的share_key(token)参数存储起来
    // store.commit('screen/updateToken', getLocationParams('share_key'));
    if (!sessionStorage['SHARE_TOKEN'] && getLocationParams('share_key')) {
      sessionStorage.setItem('SHARE_TOKEN', getLocationParams('share_key'));
    }
    try {
      const res = await fetchPermission('ds');
      const pathList = Object.keys(res);
      // 进入路由前先加载组织架构树
      await store.dispatch('screen/updateTreeList');
      // 判断是否有大屏的权限
      if (pathList.includes('/screen')) {
        next();
      } else {
        next('/403');
      }
    } catch {
      next('/403');
    }
  }

  /** scroll */
  timer = null;
  scrollLeft() {
    this.stopScroll();
    this.timer = setInterval(() => {
      this.$refs.scrollbar.scrollLeft -= 20;
    }, 10);
  }
  scrollRight() {
    this.stopScroll();
    this.timer = setInterval(() => {
      this.$refs.scrollbar.scrollLeft += 20;
    }, 10);
  }
  stopScroll() {
    clearInterval(this.timer);
  }
}
</script>

<style lang="less" module>
.tab_container {
  position: relative;
  .tab {
    width: 11rem;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: auto;
    .x_scroll {
      white-space: nowrap;
      .infoTab {
        width: 1.2rem;
        height: 0.33rem;
        position: relative;
        display: inline-block;
        cursor: pointer;
        border: 1px solid var(--screen-nav-bg);
        margin-left: 0.2rem;
        background: var(--screen-nav-bg);
        transform: skewX(-40deg);
        .navText {
          font-family: YouSheBiaoTiHei;
          width: 100%;
          height: 100%;
          font-size: 0.18rem;
          transform: skewX(40deg);
          color: var(--screen-font-3);
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 0.33rem;
          text-align: center;
        }
      }
      // .infoTab::before {
      //   content: '';
      //   width: 0.1rem;
      //   height: 0.1rem;
      //   background-color: #fff;
      //   border-radius: 50%;
      //   transform: skewX(40deg);
      //   position: absolute;
      //   bottom: 0;
      //   left: -0.04rem;
      // }
      // .infoTab::after {
      // }
      .activeTab {
        background: var(--screen-nav-active-bg);
        color: var(--screen-font-3);
        border: 1px solid var(--screen-nav-active-border);
      }
    }
  }
  .tab::-webkit-scrollbar {
    width: 0.14rem !important;
    height: 0.14rem !important;
  }
  // .left_arrow,
  // .right_arrow {
  //   height: 0.14rem;
  //   width: 0.22rem;
  //   display: block;
  //   position: absolute;
  //   bottom: 0;
  //   text-align: center;
  //   line-height: 0.14rem;
  //   background-color: #1b779c;
  //   font-size: 0.11rem;
  //   border-radius: 2px;
  // }
  // .left_arrow {
  //   left: 0;
  //   border-radius: 4px 0 0 4px;
  // }
  // .right_arrow {
  //   right: 0.16rem;
  //   border-radius: 0 4px 4px 0;
  // }
  // .left_arrow:hover,
  // .right_arrow:hover {
  //   cursor: pointer;
  //   background-color: #1f8db8;
  // }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  .childrenMenu {
    display: flex;
    margin: 0.12rem 0 0 0.18rem;
    .childrenTab {
      text-align: center;
      min-width: 0.64rem;
      height: 0.32rem;
      margin-left: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 0.14rem;
      border: 1px solid var(--screen-nav-bg);
      background: var(--screen-nav-bg);
      font-family: YouSheBiaoTiHei;
      padding: 0 0.05rem;
      white-space: nowrap;
      color: #fff;
    }
    .childrenTab:first-child {
      margin-left: 0;
    }
    .activeTab {
      background: var(--screen-nav-active-bg);
      color: var(--screen-font-3);
      border: 1px solid var(--screen-nav-active-border);
    }
  }
  .main {
    flex: 1;
    padding: 0.12rem 0.16rem 0.16rem;
    overflow: hidden;
  }
}
</style>
