/** @description 这里放socket事件类型 */

/** @name 租户中心 */
const tenantCenterTopic = 'triascloud-tenant-center';
export const TenantCenterSocket = {
  /** @name 成员信息更新 */
  MemberUpdate: `${tenantCenterTopic}:member_update`,
  /** @name 账号停用 */
  MemberDisable: `${tenantCenterTopic}:member_disable`,
  /** @name 离职 */
  MemberResign: `${tenantCenterTopic}:member_resign`,
  /** @name 租户解散 */
  TenantDisband: `${tenantCenterTopic}:tenant_disband`,
  /** @name 租户信息更新 */
  TenantUpdate: `${tenantCenterTopic}:tenant_update`,
};
