<template>
  <error-page type="404" :class="$style.notFound" />
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { ErrorPage } from '@triascloud/x-blocks';

@Component({
  components: { ErrorPage },
})
export default class NotFoundPage extends Vue {}
</script>
<style lang="less" module>
.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
