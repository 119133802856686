import Vue from 'vue';
import Vuex from 'vuex';
import { createCrossStorageVuexPlugin } from '@triascloud/cross-storage';
import { safeParseJSON } from '@/utils';
import TenantChange from '@/components/tenant-change';
import global from './modules/global.js';
import share from './modules/share.js';
import screen from './modules/screen.js';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    global,
    share,
    screen,
  },
  plugins: [
    createCrossStorageVuexPlugin({
      keys: {
        user: json => safeParseJSON(json, {}),
        skin: json => safeParseJSON(json, { theme: 'cyan', mode: 'tint' }),
        locale: val => val || 'zh_CN',
        tenant: json => safeParseJSON(json, null),
      },
      filter: ({ key, value }, initiative) => {
        // 如果是在当前窗口主动触发更新，则永远成功
        if (initiative || key !== 'tenant') return true;
        const oldTenant = store.state.crossStorage.tenant;
        const newTenant = safeParseJSON(value, null);
        if (!oldTenant || !newTenant || oldTenant.pkId === newTenant.pkId)
          return true;
        TenantChange.create(oldTenant);
        return false;
      },
    }),
  ],
});

export default store;
