<template>
  <div class="x-tenant-error">
    <div class="x-tenant-error--logo" />
    <div class="x-tenant-error--tips">
      <h3>{{ $t('tenantChange.title') }}</h3>
      <p>
        <span>{{ $t('tenantError.target') }}</span>
        <span class="x-tenant-error--name">
          {{ targetTenant && targetTenant.enterpriseName }}
        </span>
        <br />
        <span>{{ $t('tenantError.current') }}</span>
        <span class="x-tenant-error--name">
          {{ tenant && tenant.enterpriseName }}
        </span>
      </p>
      <div class="x-tenant-error--action">
        <async-button type="primary" :click="handleChangeTenant">
          {{ $t('tenantError.change') }}
        </async-button>
        <router-link to="/">
          <a-button href="/">{{ $t('tenantChange.action') }}</a-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { promiseLock } from '@triascloud/utils';
import { createModal } from '@triascloud/x-components';
import { crossStorageModule, globalModule } from '@/enum/store';
import AsyncButton from '../async-button';

function createTenantChangeModal(tenantId) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(TenantError, {
          props: { tenantId },
          on: {
            close: () => {
              modal.handleClose();
              resolve();
            },
          },
        }),
      {
        className: 'x-tenant-error--modal',
        closable: false,
        maskClosable: false,
        width: 660,
      },
    );
  });
}

@Component({
  components: { AsyncButton },
})
export default class TenantError extends Vue {
  static create = promiseLock(createTenantChangeModal, () => 'tenantError');
  @globalModule.Action changeTenant;
  @globalModule.State companyList;
  @crossStorageModule.State tenant;
  @Prop() tenantId;
  get targetTenant() {
    return this.companyList.find(tenant => tenant.pkId === this.tenantId);
  }

  created() {
    this.loadTenant();
  }

  @promiseLock({ keyGenerator: () => 'loadTenant', global: true })
  async loadTenant() {}

  async handleChangeTenant() {
    if (!this.targetTenant) {
      await this.loadTenant();
    }
    await this.changeTenant({
      tenant: this.targetTenant,
      redirect: false,
    });
    this.$emit('close');
  }
}
</script>
<style lang="less">
@tenant-error-prefix: x-tenant-error;

.@{tenant-error-prefix} {
  padding: 80px 20px 45px;
  display: flex;
}

.@{tenant-error-prefix}--modal {
  .ant-modal-mask {
    z-index: 9999;
  }
  .ant-modal-wrap {
    z-index: 10000;
  }
}

.@{tenant-error-prefix}--logo {
  width: 256px;
  height: 207px;
  margin-right: 15px;
  background-image: url('../../assets/images/expired.png');
}

.dark .@{tenant-error-prefix}--logo {
  background-image: url('../../assets/images/expired-dark.png');
}

.@{tenant-error-prefix}--tips {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  h3 {
    font-size: 22px;
    font-weight: bold;
    color: var(--font);
  }
  p {
    line-height: 32px;
  }
  .@{tenant-error-prefix}--name {
    color: var(--primary);
    margin-left: 0.5em;
  }

  .ant-btn {
    width: 140px;
    height: 34px;
    font-size: 16px;
    margin-top: 20px;
  }
}
.@{tenant-error-prefix}--action {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
