import { isNumber } from 'lodash';
import { nowSize } from '@/utils/common';
/**
 * @name 获取风向值
 * @param { number } val
 * @returns { string }
 */
export function swindDirection(val) {
  if (!isNumber(val)) return '';
  const arr = [
    {
      name: '北',
      value: [348.76, 360],
    },
    {
      name: '北',
      value: [0, 11.25],
    },
    {
      name: '北东北',
      value: [11.26, 33.75],
    },
    {
      name: '东北',
      value: [33.76, 56.25],
    },
    {
      name: '东东北',
      value: [56.26, 78.75],
    },
    {
      name: '东',
      value: [78.76, 101.25],
    },
    {
      name: '东东南',
      value: [101.26, 123.75],
    },
    {
      name: '东南',
      value: [123.76, 146.25],
    },
    {
      name: '南东南',
      value: [146.26, 168.75],
    },
    {
      name: '南',
      value: [168.76, 191.25],
    },
    {
      name: '南西南',
      value: [191.26, 213.75],
    },
    {
      name: '西南',
      value: [213.76, 236.25],
    },
    {
      name: '西西南',
      value: [236.26, 258.75],
    },
    {
      name: '西',
      value: [258.76, 281.25],
    },
    {
      name: '西西北',
      value: [281.26, 303.75],
    },
    {
      name: '西北',
      value: [303.76, 326.25],
    },
    {
      name: '北西北',
      value: [326.26, 348.75],
    },
  ];
  return arr.find(({ value }) => val >= value[0] && val <= value[1]).name;
}

//计算pie图中间的值
export const getPieChartTotal = res => {
  // window.console.log(res, 'getPieChartTotal');
  let total = 0,
    ret = [];
  if (typeof res != 'object' || !res.length) return [ret, total];

  ret = res.map(item => {
    total += item.tagNum;
    return {
      value: item.tagNum,
      name: item.tagName,
    };
  });
  return [ret, total];
};

export const getPieChartDesc = data => {
  // window.console.log(data, 'getPieChartDesc');
  const ret = {
    show: true,
    position: 'center',
    formatter: `{a|总数}\n{b|${data}}`,
    rich: {
      a: {
        color: '#9AC0D9',
        fontSize: nowSize(12),
      },
      b: {
        color: '#fff',
        textShadowColor: '#7DB6FF',
        textShadowBlur: 8,
        fontSize: nowSize(18),
        padding: [nowSize(8), 0, 0, 0],
      },
    },
  };
  return ret;
};

export const formatSeconds = (value, isM = false) => {
  if (value < 0) return 0;
  let result = '';
  if (!isM) {
    let theTime = parseInt(value); // 秒
    let theTime1 = 0; // 分
    let theTime2 = 0; // 小时
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60);
        theTime1 = parseInt(theTime1 % 60);
      }
    }
    result = '' + parseInt(theTime) + '秒';
    if (theTime1 > 0) {
      result = '' + parseInt(theTime1) + '分' + result;
    }
    if (theTime2 > 0) {
      result = '' + parseInt(theTime2) + '小时' + result;
    }
  } else {
    let theTime = parseInt(value); // 分
    let theTime1 = 0; // 时
    result = '0小时' + theTime + '分';
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      result = '' + theTime1 + '小时' + theTime + '分';
    }
  }
  return result;
};

export const getSiteURL = () => {
  const url = {
    dev: `http://dev.app.${process.env.VUE_APP_HOST}/link/`,
    production: `https://app.${process.env.VUE_APP_HOST}/link/`,
    sit: `https://sit.app.${process.env.VUE_APP_HOST}/link/`,
    uat: `https://uat.app.${process.env.VUE_APP_HOST}/link/`,
  };

  return url[process.env.VUE_APP_ENV];
};

export const formatHours = time => {
  if (time <= 0) return 0;
  return (time / 60).toFixed(1);
};

export const getLocationParams = keyWords => {
  // 提取路由值（字符串）
  const href = window.location.href;
  // 从占位符开始截取路由（不包括占位符）
  const query = href.substring(href.indexOf('?') + 1);
  // 根据 & 切割字符串
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    // 根据指定的参数名去筛选参数值
    if (pair[0] === keyWords) {
      return pair[1];
    }
  }
  return '';
};
