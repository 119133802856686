<template>
  <layout :class="$style.index">
    <layout-menu
      :menus="menus"
      :class="$style.menus"
      :value="menu ? menu.path : $route.path"
    >
      <template v-slot:header-left>
        <x-icon type="tc-icon-company" :class="$style.icon" />
        <span :class="$style.leftTitle">{{
          $t('common.deviceManagement')
        }}</span>
      </template>
    </layout-menu>
    <layout-content :class="$style.content">
      <template v-slot:header-left>
        <span :class="$style.rightTitle">{{ title }}</span>
      </template>
      <router-view slot="content" />
    </layout-content>
  </layout>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Layout, LayoutContent, LayoutMenu } from '@triascloud/x-blocks';
import { findTree } from '@triascloud/utils';
import { fetchPermission } from '@/services/production/module-auth';

@Component({
  components: {
    Layout,
    LayoutContent,
    LayoutMenu,
  },
})
export default class IndexPage extends Vue {
  get menus() {
    return [
      {
        path: '/construction',
        title: this.$t('greenConstruction.title'),
        children: [
          {
            path: '/construction/dustMonitor',
            title: this.$t('path.construction/dustMonitor'),
          },
          {
            path: '/construction/sprayControl',
            title: this.$t('path.construction/sprayControl'),
          },
        ],
      },
      {
        path: 'personnel',
        title: this.$t('peopleManagement.title'),
        children: [
          {
            path: '/personnel/faceGate',
            title: this.$t('path.personnel/faceGate'),
          },
          {
            path: '/personnel/smartHelmet',
            title: this.$t('path.personnel/smartHelmet'),
          },
        ],
      },
      {
        path: 'car',
        title: this.$t('carManagement.title'),
        children: [
          {
            path: '/car/vehiclePassage',
            title: this.$t('path.car/vehiclePassage'),
          },
          {
            path: '/car/vehicleCleaning',
            title: this.$t('path.car/vehicleCleaning'),
          },
          {
            path: '/car/weighbridge',
            title: this.$t('path.car/weighbridge'),
          },
        ],
      },
      {
        path: 'video',
        title: this.$t('videoMonitor.title'),
        children: [
          {
            path: '/video/monitoring',
            title: this.$t('path.video/monitoring'),
          },
          // {
          //   path: '/videoManagement/aiVideo',
          //   title: 'AI视频分析系统',
          // },
        ],
      },
      {
        path: 'safety',
        title: this.$t('safety.safetyDetectionEquipment'),
        children: [
          {
            path: '/safety/towerCrane',
            title: this.$t('path.safety/towerCrane'),
          },
          {
            path: '/safety/liftMonitor',
            title: this.$t('path.safety/liftMonitor'),
          },
          {
            path: '/safety/nacelleMonitor',
            title: this.$t('path.safety/nacelleMonitor'),
          },
          {
            path: '/safety/unloadMonitor',
            title: this.$t('path.safety/unloadMonitor'),
          },
          {
            path: '/safety/highFormwork',
            title: this.$t('path.safety/highFormwork'),
          },
          {
            path: '/safety/pitMonitor',
            title: this.$t('path.safety/pitMonitor'),
          },
          {
            path: '/safety/concreteMonitor',
            title: this.$t('path.safety/concreteMonitor'),
          },
          {
            path: '/safety/smoke',
            title: this.$t('path.safety/smoke'),
          },
          {
            path: '/safety/screwMonitor',
            title: this.$t('path.safety/screwMonitor'),
          },
          {
            path: '/safety/edgeProtection',
            title: '临边防护',
          },
          {
            path: '/safety/aiBox',
            title: 'AI盒子',
          },
          {
            path: '/safety/standardCuringRoom',
            title: '标养室监测',
          },
        ],
      },
      {
        path: 'waterElectricity',
        title: this.$t('safety.hydroelectricFacility'),
        children: [
          {
            path: '/waterElectricity/waterMeter',
            title: this.$t('path.waterElectricity/waterMeter'),
          },
          {
            path: '/waterElectricity/electricityMeter',
            title: this.$t('path.waterElectricity/electricityMeter'),
          },
          {
            path: '/waterElectricity/sewageMonitor',
            title: this.$t('path.waterElectricity/sewageMonitor'),
          },
        ],
      },
      {
        path: 'auth',
        title: '权限管理',
        children: [
          {
            path: '/auth/nav-permissions',
            title: '导航栏权限',
          },
        ],
      },
    ].filter(item => {
      // 过滤权限菜单
      if (item.children) {
        item.children = item.children.filter(
          child => this.$p(child.alias || child.path) || child.auth === false,
        );
        return item.children.length;
      } else {
        return this.$p(item.alias || item.path) || item.auth === false;
      }
    });
  }

  get menu() {
    let menu = findTree(this.menus, item => item.path === this.$route.path);
    if (!menu) {
      menu = findTree(
        this.menus,
        item =>
          item.path ===
          this.$route.matched[this.$route.matched.length - 2]?.path,
      );
    }
    return menu;
  }
  get title() {
    return this.menu && this.menu.title
      ? this.menu.title
      : this.$path(this.$route.path, 'ds');
  }
  @Watch('menus')
  redirect() {
    if (this.$route.path === '/') {
      const path =
        this.menus[0] &&
        this.menus[0].children &&
        this.menus[0].children[0] &&
        this.menus[0].children[0].path;
      path && this.$router.replace(path);
    }
  }
  async beforeRouteEnter(to, from, next) {
    try {
      const res = await fetchPermission('ds');
      const pathList = Object.keys(res);
      if (pathList.includes('/deviceManagement')) {
        next();
      } else {
        next('/403');
      }
    } catch {
      next('/403');
    }
  }
}
</script>
<style lang="less" module>
.menus {
  width: 200px;
  .icon {
    font-size: 18px;
    margin-right: 10px;
  }
  .leftTitle {
    font-size: var(--font-md);
  }
}
.content {
  flex: 1;
  .rightTitle {
    font-size: var(--font-md);
  }
}
:global {
  .x-layout-content-fullScreen-icon {
    font-size: var(--font-md);
  }
}
</style>
