import { Vue } from 'vue-property-decorator';
export const Emitter = new Vue();

// 是否全是-1
export const isAllNegative = arr => {
  let flag = false;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== -1) {
      flag = true;
      break;
    }
  }
  return flag ? arr.map(d => (d !== -1 ? d : '')) : [];
};
