<template>
  <a-select
    class="x-layout-select"
    v-on="$listeners"
    v-bind="$attrs"
    dropdownClassName="x-layout-select-dropdown"
    ><slot
  /></a-select>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon/index.vue';

@Component({
  components: { AliIcon },
})
export default class LayoutSelect extends Vue {}
</script>
<style lang="less">
// .x-layout-select {
//   width: 1.04rem;
//   font-size: 0.14rem;
//   background: linear-gradient(180deg, #18222f 59%, #506883 100%);
//   box-shadow: inset 0px -4px 6px 0px #556b86;
//   border-image: linear-gradient(
//       360deg,
//       rgba(150.83535343408585, 177.73290932178497, 209.61000680923462, 0),
//       rgba(166.27333223819733, 194.38203513622284, 227.7405059337616, 1)
//     )
//     2 2;
//   color: #fff;
//   .ant-select-selection--single {
//     border-radius: 0;
//     height: 0.32rem;
//   }
//   .ant-select-selection__rendered {
//     line-height: 0.32rem;
//   }
//   .ant-select-search__field__placeholder,
//   .ant-select-selection__placeholder {
//     color: #fff;
//   }
//   .ant-select-selection {
//     border: 2px solid;
//     border-image: linear-gradient(
//         360deg,
//         rgba(150.83535343408585, 177.73290932178497, 209.61000680923462, 0),
//         rgba(166.27333223819733, 194.38203513622284, 227.7405059337616, 1)
//       )
//       2 2;
//   }
//   & + * {
//     margin-left: 0.05rem;
//   }
// }
// .x-layout-select-dropdown {
//   background: var(--screen-select-dropdown-bg);
//   .ant-select-dropdown-menu {
//     padding: 0;
//   }
//   .ant-select-dropdown-menu-item {
//     color: var(--screen-font-1);
//     font-size: 0.14rem;
//   }
//   .ant-select-dropdown-menu-item:hover {
//     background-color: rgba(60, 92, 121, 0.8);
//   }
//   .ant-select-dropdown-menu-item-selected {
//     background-color: rgba(60, 92, 121, 1);
//   }
//   .x-empty-content--label {
//     color: #9ac0d9;
//     font-size: 0.12rem;
//   }
//   .ant-select-dropdown-menu-item-selected {
//     background: var(--screen-select-dropdown-active-bg);
//     color: var(--screen-font-3);
//   }
// }
</style>
