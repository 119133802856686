var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutContainer',{attrs:{"headerBackground":require('@/assets/images/theme/header-project.png')}},[_c('template',{slot:"headerLeft"},[_c('search-project',{ref:"searchProject",on:{"select":_vm.onSelect}})],1),_c('div',{class:_vm.$style.tab_container,attrs:{"slot":"headerMiddle"},slot:"headerMiddle"},[_c('div',{ref:"scrollbar",staticClass:"screen_scrollbar",class:_vm.$style.tab},[_c('div',{class:_vm.$style.x_scroll},_vm._l((_vm.tabList),function(item){return _c('div',{key:item.menuId,class:[
            _vm.$style.infoTab,
            item.menuId === _vm.presentRouter ? _vm.$style.activeTab : '',
          ],attrs:{"title":item.text},on:{"click":function($event){return _vm.changeHeaderTab(item)}}},[_c('div',{class:_vm.$style.navText},[_vm._v(_vm._s(item.text))])])}),0)])]),_c('div',{class:_vm.$style.content},[(_vm.childrenTabList.length)?_c('div',{class:_vm.$style.childrenMenu},_vm._l((_vm.childrenTabList),function(item){return _c('div',{key:item.menuId,class:[
          _vm.$style.childrenTab,
          item.menuId === _vm.activeTab ? _vm.$style.activeTab : '',
        ],on:{"click":function($event){return _vm.changeTab(item)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0):_vm._e(),_c('div',{class:_vm.$style.main},[(_vm.isRouterAlive)?_c('router-view',{attrs:{"url":_vm.url,"projectName":_vm.projectName}}):_vm._e()],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }