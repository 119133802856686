import request from '../request';

const serviceName = '/production/module-auth';

export function fetchPermission(module) {
  return request(serviceName, {
    method: 'GET',
    body: { module },
  });
}

// export function fetchPermission() {
//   return Promise.resolve({
//     '/digitalSite/generalView': [],
//     '/digitalSite/iotScreenManage': [],
//     '/digitalSite/electricBoxMock': [],
//   });
// }
