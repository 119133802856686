import Vue from 'vue';
import { VuePermissionRouter } from '@triascloud/permission';
import { fetchPermission } from '@/services/production/module-auth';
import NotFoundPage from '@/views/404.vue';
import ForbiddenPage from '@/views/403.vue';
import DeviceManagement from './module/management';
import Screen from './module/screen';
import ShareScreen from './module/share';

Vue.use(VuePermissionRouter);
/**
 * @type {import('vue-router').RouteConfig[]} }
 * @description
 * 1、meta: { auth: false } auth = false，不需要加载权限也能访问。auth = true，加载权限并且要求具有页面访问权限
 * 未配置auth的情况下，页面静默加载权限（要求登录），但后台不配置权限也可以访问
 * 2、meta: { share: true } share = true 页面可分享，【接口】需要加入【白名单】
 * request 请求接口配置如下：
    request(`/oss/backend/getAuth`, {
      method: 'POST',
      body: data,
      auth: false, // 【前端】需要设置auth为false，同时这个接口后端也需要加入【白名单】中
    });
 */
const routes = [
  /** @name 菜单设备管理页面 */
  ...DeviceManagement,
  /** @name 大屏页面 */
  ...Screen,
  /** @name 分享大屏页面 */
  ...ShareScreen,
  /** @name 无开通权限页面 */
  {
    path: '/noPermission',
    name: 'noPermission',
    component: () => import('@/views/no-permission/index.vue'),
    meta: {
      title: '数字工地',
    },
  },
  {
    path: '/403',
    name: '403',
    component: ForbiddenPage,
  },
  /** @name 404页面 */
  {
    path: '/404',
    component: NotFoundPage,
  },
  /** @name 404页面 */
  {
    path: '*',
    name: '404',
    component: NotFoundPage,
  },
];

const router = new VuePermissionRouter({
  fetch: () => fetchPermission('ds'),
  forbiddenRoute: '/403',
  mode: 'history',
  base: process.env.BASE_URL, // /ds/
  routes,
});

export default router;
