<template>
  <div class="x-empty-content" :class="`x-empty-content--type-${type}`">
    <div class="x-empty-content--image" />
    <x-ellipsis class="x-empty-content--label" :row="1" v-if="computedLabel">
      {{ computedLabel }}
    </x-ellipsis>
    <slot />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component()
export default class EmptyContent extends Vue {
  @Prop({ type: String, default: '' }) label;
  get computedLabel() {
    return this.label == null
      ? this.$t('common.label.emptyContent')
      : this.label;
  }
  /**
   * @name 空态图类型
   * 默认	default
   * 主页	home
   * 列表	list
   * 搜索	search
   * 拖拽 drag
   * 权限 permission
   * 收藏 favorite
   * 下载 download
   * 消息 message
   */
  @Prop({ type: String, default: 'default' }) type;
}
</script>
<style lang="less">
.x-empty-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.x-empty-content--image {
  width: 80%;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.x-empty-content--type-default .x-empty-content--image {
  background-image: var(--empty-image);
}
.x-empty-content--type-home .x-empty-content--image {
  background-image: var(--empty-home-image);
}
.x-empty-content--type-list .x-empty-content--image {
  background-image: var(--empty-list-image);
}
.x-empty-content--type-search .x-empty-content--image {
  background-image: var(--empty-search-image);
}
.x-empty-content--type-drag .x-empty-content--image {
  background-image: var(--empty-drag-image);
}
.x-empty-content--type-permission .x-empty-content--image {
  background-image: var(--empty-permission-image);
}
.x-empty-content--type-favorite .x-empty-content--image {
  background-image: var(--empty-favorite-image);
}
.x-empty-content--type-download .x-empty-content--image {
  background-image: var(--empty-download-image);
}
.x-empty-content--type-message .x-empty-content--image {
  background-image: var(--empty-message-image);
}
.x-empty-content--label {
  margin-top: 5px;
  margin-left: 0;
  font-size: 16px;
  color: var(--font-info);
  display: inline-block;
}
</style>
