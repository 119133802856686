import MainLayout from '@/layout/main.vue';
import IndexPage from '@/views/index.vue';
import GreenConstruction from '@/views/green-construction/index.vue';
import VideoManagement from '@/views/video-management/index.vue';
import PersonnelManagement from '@/views/personnel-management/index.vue';
import CarManagement from '@/views/car-management/index.vue';
import WaterElectricity from '@/views/water-electricity/index.vue';
import AuthorityManagement from '@/views/authority-management/index.vue';

/**
 * 菜单设备管理路由
 */
const management = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/',
        component: IndexPage,
        children: [
          {
            path: '/construction',
            name: 'construction',
            component: GreenConstruction,
            children: [
              {
                path: 'dustMonitor',
                name: 'dust-monitor',
                component: () =>
                  import('@/views/green-construction/dust-monitor'),
                meta: { auth: true },
              },
              {
                path: 'sprayControl',
                name: 'spray-control',
                component: () =>
                  import('@/views/green-construction/spray-control'),
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/personnel',
            name: 'personnel',
            component: PersonnelManagement,
            children: [
              {
                path: 'faceGate',
                name: 'face-gate',
                component: () =>
                  import('@/views/personnel-management/face-gate'),
                meta: { auth: true },
              },
              {
                path: 'smartHelmet',
                name: 'smart-helmet',
                component: () =>
                  import('@/views/personnel-management/smart-helmet'),
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/car',
            name: 'car',
            redirect: '/car/vehicle-passage',
            component: CarManagement,
            children: [
              {
                path: 'vehiclePassage',
                name: 'vehicle-passage',
                component: () =>
                  import('@/views/car-management/vehicle-passage'),
                meta: { auth: true },
              },
              {
                path: 'vehicleCleaning',
                name: 'vehicle-cleaning',
                component: () =>
                  import('@/views/car-management/vehicle-cleaning'),
                meta: { auth: true },
              },
              {
                path: 'weighbridge',
                name: 'weighbridge',
                component: () =>
                  import('@/views/car-management/intelligent-weighbridge'),
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/video',
            name: 'video',
            component: VideoManagement,
            children: [
              {
                path: 'monitoring',
                name: 'video-surveillance',
                component: () =>
                  import('@/views/video-management/video-surveillance'),
                meta: { auth: true },
              },
              // {
              //   path: 'aiVideo',
              //   name: 'ai-video',
              //   component: () => import('@/views/video-management/ai-video'),
              //   meta: { auth: true },
              // },
            ],
          },
          {
            path: '/safety',
            name: 'safety',
            component: GreenConstruction,
            children: [
              {
                path: 'towerCrane',
                name: 'tower-crane',
                component: () => import('@/views/safety-device/tower-crane'),
                meta: { auth: true },
              },
              {
                path: 'liftMonitor',
                name: 'lift-monitor',
                component: () => import('@/views/safety-device/lift-monitor'),
                meta: { auth: true },
              },
              {
                path: 'nacelleMonitor',
                name: 'nacelle-monitor',
                component: () =>
                  import('@/views/safety-device/nacelle-monitor'),
                meta: { auth: true },
              },
              {
                path: 'unloadMonitor',
                name: 'unload-monitor',
                component: () => import('@/views/safety-device/unload-monitor'),
                meta: { auth: true },
              },
              {
                path: 'highFormwork',
                name: 'high-formwork-monitor',
                component: () => import('@/views/safety-device/high-formwork'),
                meta: { auth: true },
              },
              {
                path: 'pitMonitor',
                name: 'pit-monitor',
                component: () => import('@/views/safety-device/pit-monitor'),
                meta: { auth: true },
              },
              {
                path: 'concreteMonitor',
                name: 'concrete-monitor',
                component: () =>
                  import('@/views/safety-device/concrete-monitor'),
                meta: { auth: true },
              },
              {
                path: 'smoke',
                name: 'smoke',
                component: () => import('@/views/safety-device/smoke-detector'),
                meta: { auth: true },
              },
              {
                path: 'screwMonitor',
                name: 'screw-monitor',
                component: () => import('@/views/safety-device/screw-monitor'),
                meta: { auth: true },
              },
              {
                path: 'edgeProtection',
                name: 'edge-protection',
                component: () =>
                  import('@/views/safety-device/edge-protection'),
                meta: { auth: true },
              },
              {
                path: 'aiBox',
                name: 'ai-box',
                component: () => import('@/views/safety-device/ai-box'),
                meta: { auth: true },
              },
              {
                path: 'standardCuringRoom',
                name: 'standard-curing-room',
                component: () =>
                  import('@/views/safety-device/standard-curing-room'),
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/waterElectricity',
            name: 'waterElectricity',
            component: WaterElectricity,
            children: [
              {
                path: 'waterMeter',
                name: 'water-meter',
                component: () =>
                  import('@/views/water-electricity/water-meter'),
                meta: { auth: true },
              },
              {
                path: 'electricityMeter',
                name: 'electricity-meter',
                component: () =>
                  import('@/views/water-electricity/electricity-meter'),
                meta: { auth: true },
              },
              {
                path: 'sewageMonitor',
                name: 'sewage-monitor',
                component: () =>
                  import('@/views/water-electricity/sewage-monitor'),
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/auth',
            name: ' Auth',
            component: AuthorityManagement,
            children: [
              {
                path: 'nav-permissions',
                name: 'nav-permissions',
                component: () =>
                  import('@/views/authority-management/nav-permissions'),
                meta: { auth: true },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/deviceManagement',
    redirect: '/',
  },
];

export default management;
