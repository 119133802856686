<template>
  <a-button
    :class="[$style.button, { [$style.active]: active }]"
    v-on="$listeners"
    v-bind="$attrs"
    ><slot
  /></a-button>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon/index.vue';

@Component({
  components: { AliIcon },
})
export default class LayoutButton extends Vue {
  @Prop({ type: Boolean, default: false }) active;
}
</script>
<style module lang="less">
.button {
  border: 1px solid var(--screen-nav-bg);
  background: var(--screen-nav-bg);
  color: var(--screen-font-3);
  height: 0.24rem;
  line-height: 0.24rem;
  font-size: 0.14rem;
  min-width: 0.76rem;
  white-space: nowrap;
  padding: 0 0.05rem;
  transition: none;
  border-radius: 0;
  &:hover,
  &.active {
    background: var(--screen-nav-active-bg);
    color: var(--screen-font-3);
    border: 1px solid var(--screen-nav-active-border);
  }
  & + * {
    margin-left: 0.05rem;
  }
}
</style>
