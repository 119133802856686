import { promiseConcat } from '@triascloud/utils';
import request from '../request';
/**
 * @name 未登录状态下获取文件访问路径
 */
export const getOssPathWithoutAuth = promiseConcat(
  /** @type { (path:string[]) => Promise<string[]>} */
  path =>
    request('/oss/backend/getAuth', {
      method: 'POST',
      body: path,
      auth: false,
    }),
);
