import { ensureLocale } from '@triascloud/i18n';
import request from '../request';
import { ThemeSelect } from '@triascloud/x-blocks';

const serviceName = '/template-manager/website';

/**
 * @name 自定义应用下域名固定
 * @param {string} module
 * @param {string} protocol
 * @returns
 */
export const createDefaultModuleUrl = (module, protocol = location.protocol) =>
  `${protocol}//${
    process.env.VUE_APP_ENV === 'production'
      ? ''
      : `${process.env.VUE_APP_ENV}.`
  }${module}.${process.env.VUE_APP_HOST}`;

/**
 * @typedef { certificate: string; certificateKey: string; host: string; protocols: 'http'|'https' } Domain
 */

/**
 * @typedef DomainSetting
 * @property { Domain } mobile
 * @property { Domain } pc
 * @property { string } providerMsg
 * @property { string } providerNo
 * @property { string } sysBgImg
 * @property { string } sysName
 * @property { string } visitIndex
 * @property { number } providerIsJump
 */

/** @name 根据域名获取应用模式配置 */
export const getSettingByDomain = domain =>
  request(
    `${createDefaultModuleUrl(
      'api',
      'https:',
    )}${serviceName}/custom/domainPublicConfig?domain=${domain}`,
    {
      auth: false,
    },
  );

/**
 * @name 获取当前用户信息（包括用户信息、当前企业、皮肤和语言）
 * @param { string } token
 * @returns
 */
export async function getCurrentInfoByToken(token) {
  const options = { headers: { 'X-Access-Token': token }, auth: false };
  const [user, tenants, { skin, locale }] = await Promise.all([
    request('/tenant-center/tenant/member', options),
    request('/tenant-center/enterprise/memberEnterprise', options),
    request('/configuration/memberHabit/get', {
      method: 'POST',
      body: ['skin', 'locale'],
      ...options,
    }),
  ]);
  return {
    user: JSON.stringify(user),
    tenant: JSON.stringify(
      tenants.find(tenant => tenant?.pkId === user.tenant?.tenantId),
    ),
    skin: JSON.stringify(ThemeSelect.ensureTheme(skin)),
    locale: ensureLocale(locale),
  };
}
