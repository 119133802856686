import { Emitter } from '@/views/screen/components/screen-utils';

/** @name 分享数据 */
const KEY_SHARE_DATA = '__s_data__';
const getData = () =>
  localStorage.getItem(KEY_SHARE_DATA)
    ? JSON.parse(localStorage.getItem(KEY_SHARE_DATA))
    : {
        password: '',
        shareId: '',
        projectId: '',
      };
const setData = value =>
  localStorage.setItem(KEY_SHARE_DATA, JSON.stringify(value));
/** @type { import('vuex').Module } */
const share = {
  namespaced: true,
  state: {
    data: {
      password: getData().password,
      shareId: getData().shareId,
      projectId: getData().projectId,
    },
  },
  mutations: {
    updateData(state, payload) {
      state.data = { ...payload };
    },
  },
  actions: {
    updateLocalData({ commit }, payload) {
      commit('updateData', payload);
      setData(payload);
      Emitter.$emit('update-share', payload.projectId);
    },
  },
};

export default share;
