/** @name 大屏页面模板路由枚举 */
export const templateEnum = {
  /** 江苏省模板 */
  XIANG_MU_SHOU_YE_JIANG_SU: 'overview', // 项目首页
  GONG_SI_SHOU_YE_STANDARD: 'screen-map', // 公司首页
  LV_SE_SHI_GONG_JIANG_SU: 'green-construction',
  LAO_WU_GUAN_LI_JIANG_SU: 'people-management',
  SHI_PING_JIAN_KONG_JIANG_SU: 'video-monitor',
  CHE_LIANG_GUAN_LI_JIANG_SU: 'car-management',
  XIAN_CHANG_JIAN_CHA_JIANG_SU: 'spot-checking',
  AN_QUAN_JIAN_CE_JIANG_SU: 'safety',
  SHENG_JIANG_JI_JIANG_SU: 'safety/lift',
  XIE_LIAO_PING_TAI_JIANG_SU: 'safety/unload',
  TA_DIAO_JIANG_SU: 'safety/tower',
  LIN_BIAN_FANG_HU_JIANG_SU: 'safety/protection',
  SHEN_JI_KENG_JIANG_SU: 'safety/pit',
  GAO_ZHI_MO_JIANG_SU: 'safety/formwork',
  ZHI_NENG_DI_BANG_JIANG_SU: 'intelligent-weighbridge',
  SHUI_DIAN_JIAN_CE_JIANG_SU: 'water-electricity',
  AI_SHI_BIE_JIANG_SU: 'ai-recognition',
  JIN_DU_GUAN_LI_JIANG_SU: 'progress-control',
};
