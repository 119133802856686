import Vue from 'vue';
import { getCurrentTenantInfoList } from '@/services/tenant-center/enterprise';
import { crossStorage } from '@triascloud/cross-storage';
import { getCurrentUser } from '@/services/tenant-center/member';
import { getCurrentDepartmentList } from '@/services/tenant-center/department';
import { ensureArray, promiseLock } from '@triascloud/utils';
/** @type { import('vuex').Module } */
const global = {
  namespaced: true,
  state: {
    menuData: {
      collection: null,
      menu: null,
      recent: null,
    },
    companyList: [],
    departmentList: [],
    refreshMenuFlag: false,
    refreshMessageFlag: false,
  },
  mutations: {
    updateCompanyList(state, payload) {
      state.companyList = ensureArray(payload);
    },
    updateDepartmentList(state, payload) {
      state.departmentList = ensureArray(payload);
    },
    updateRefreshMenuFlag(state, payload) {
      state.refreshMenuFlag = !!payload;
    },
    refreshMessage(state) {
      state.refreshMessageFlag = !state.refreshMessageFlag;
    },
    updateMenuData(state, payload) {
      state.menuData = { ...payload };
    },
  },
  actions: {
    /**
     * @name 加载公司列表
     * @description 切换企业、更新企业设置、退出企业、解散企业等操作时触发，会更新左上角企业列表与当前企业信息
     */
    async refreshCompanyList({ commit, dispatch, rootState }) {
      try {
        dispatch('refreshUser');
        const companyList = await getCurrentTenantInfoList();
        commit('updateCompanyList', companyList);
        const { tenant } = rootState.crossStorage;
        const newTenant =
          companyList.find(item => item.pkId === (tenant && tenant.pkId)) || // 最新的用户当前企业信息
          companyList.find(item => item.mainEnterprise === true) || // 用户主企业
          companyList[0] || // 列表第一个企业
          null;
        crossStorage.setItem('tenant', JSON.stringify(newTenant));
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async refreshDepartmentList({ commit }) {
      commit('updateDepartmentList', await getCurrentDepartmentList());
    },
    refreshUser: promiseLock(
      async ({ dispatch }) => {
        const [user] = await Promise.all([
          getCurrentUser(),
          dispatch('refreshDepartmentList'),
        ]);
        crossStorage.setItem('user', JSON.stringify(user));
      },
      () => 'refreshUser',
    ),
    async refreshMenu({ commit }) {
      commit('updateRefreshMenuFlag', true);
      await Vue.nextTick();
      commit('updateRefreshMenuFlag', false);
    },
  },
};

export default global;
