// 进入和退出全屏
function fullScreen() {
  if (
    (document.fullScreenElement !== undefined &&
      document.fullScreenElement === null) ||
    (document.msFullscreenElement !== undefined &&
      document.msFullscreenElement === null) ||
    (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
    (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)
  ) {
    if (document.documentElement.requestFullScreen) {
      document.documentElement.requestFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
      document.documentElement.webkitRequestFullScreen(
        Element.ALLOW_KEYBOARD_INPUT,
      );
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

function getDateStr(objD) {
  let yy = objD.getYear();
  if (yy < 1900) yy = yy + 1900;
  let MM = objD.getMonth() + 1;
  if (MM < 10) MM = '0' + MM;
  let dd = objD.getDate();
  if (dd < 10) dd = '0' + dd;
  let hh = objD.getHours();
  if (hh < 10) hh = '0' + hh;
  let mm = objD.getMinutes();
  if (mm < 10) mm = '0' + mm;
  let ss = objD.getSeconds();
  if (ss < 10) ss = '0' + ss;
  return yy + '年' + MM + '月' + dd + '日 ' + hh + ':' + mm + ':' + ss;
}

function dateDance(time) {
  document.querySelector('.dateStr').innerHTML = getDateStr(time);
  //   document.getElementById('localtime').innerHTML = getDateStr(today);
  window.setTimeout(dateDance, 1000);
}

function getAreaName(name, hasPingyin = false) {
  // 定义全国省份的数组
  const provinces = [
    'shanghai',
    'hebei',
    'shanxi',
    'neimenggu',
    'liaoning',
    'jilin',
    'heilongjiang',
    'jiangsu',
    'zhejiang',
    'anhui',
    'fujian',
    'jiangxi',
    'shandong',
    'henan',
    'hubei',
    'hunan',
    'guangdong',
    'guangxi',
    'hainan',
    'sichuan',
    'guizhou',
    'yunnan',
    'xizang',
    'shanxi1',
    'gansu',
    'qinghai',
    'ningxia',
    'xinjiang',
    'beijing',
    'tianjin',
    'chongqing',
    'xianggang',
    'aomen',
    'taiwan',
  ];

  const provincesText = [
    '上海',
    '河北',
    '山西',
    '内蒙古',
    '辽宁',
    '吉林',
    '黑龙江',
    '江苏',
    '浙江',
    '安徽',
    '福建',
    '江西',
    '山东',
    '河南',
    '湖北',
    '湖南',
    '广东',
    '广西',
    '海南',
    '四川',
    '贵州',
    '云南',
    '西藏',
    '陕西',
    '甘肃',
    '青海',
    '宁夏',
    '新疆',
    '北京',
    '天津',
    '重庆',
    '香港',
    '澳门',
    '台湾',
  ];
  let data = '';
  for (let i = 0; i < provincesText.length; i++) {
    if (name.indexOf(provincesText[i]) > -1) {
      if (hasPingyin) {
        data = {
          text: provincesText[i],
          pingyin: provinces[i],
        };
      } else {
        data = provincesText[i];
      }
      break;
    }
  }
  // 如果没匹配到，则返回原本数据
  if (!data) {
    if (hasPingyin) {
      data = {
        text: name,
        pingyin: '',
      };
    } else {
      data = name;
    }
  }
  // let data = provincesText.forEach((item,key) => {
  //   return name.indexOf(item);
  // });
  return data;
}

/**
 * @name 由一个组件，找到指定的兄弟组件
 * @param { object } context
 * @param { string } componentName
 */
function findBrothersComponents(context, componentName) {
  return context.$parent.$children.filter(item => item.name === componentName);
}

/**
 * @name 显示el-form表单字段校验内容
 * @param { Object } options
 * @param { Boolean } showOne // 只展示一项
 */
function showMessageByVNode(options, showOne = false) {
  try {
    const h = this.$createElement;
    let list = [];
    Object.keys(options).map(key => {
      list.push(
        h('div', { style: { color: '#e6a23c' } }, options[key][0].message),
      );
    });
    if (showOne) {
      list = list.length ? list.slice(0, 1) : [];
    }
    this.$message({
      message: h('section', null, list),
      type: 'warning',
      duration: 2000,
    });
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

/**
 * @name 判断是否为PC端
 * @return Boolean
 */
function isPC() {
  const userAgentInfo = navigator.userAgent;
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

/**
 * @name size大小计算（px）
 * @description 底稿是1920*1080
 */
function nowSize(value, initwidth = 1920) {
  const nowClientWidth = document.documentElement.clientWidth;
  return value * (nowClientWidth / initwidth);
}

export {
  fullScreen,
  getDateStr,
  dateDance,
  getAreaName,
  findBrothersComponents,
  showMessageByVNode,
  isPC,
  nowSize,
};
