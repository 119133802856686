import { singleton } from 'alife-logger';

function getEnvironment() {
  switch (process.env.VUE_APP_ENV) {
    case 'dev':
      return location.host.includes(process.env.VUE_APP_HOST)
        ? 'daily'
        : 'local';
    case 'uat':
      return 'pre';
    case 'prod':
      return 'prod';
    default:
      return 'local';
  }
}

export default singleton({
  pid: 'i4zsb1fnm3@4aa8695e342e1ff',
  environment: getEnvironment(),
  // 进行前后端链路追踪，请参见使用前后端链路追踪诊断API错误原因(https://help.aliyun.com/document_detail/91409.html)。
  enableLinkTrace: true,
  // 资源上报, 用于慢会话追踪
  sendResource: true,
  // 关闭接口调用追踪
  disableHook: true,
});
