<template>
  <div :class="$style.forbidden">
    <div :class="['x-error-page', `x-error-page--type-404`]">
      <div class="x-error-page--icon" />
      <div class="x-error-page--main-tip">
        {{ flag ? $t('errorPage.403', 'package') : msg }}
      </div>
      <a-button type="primary" class="x-error-page--btn">
        <a :href="createModuleUrl('app')">
          {{ $t('errorPage.back', 'package') }}
        </a>
      </a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { ErrorPage } from '@triascloud/x-blocks';
import { createModuleUrl } from '@/utils';
import { fetchPermission } from '@/services/production/module-auth';

@Component({
  components: { ErrorPage },
})
export default class ForbiddenPage extends Vue {
  createModuleUrl = createModuleUrl;
  created() {
    this.getAuthList();
  }
  flag = true;
  msg = '需同时开通“设备管理”和指定设备管理模块，请开通后再访问！';
  async getAuthList() {
    const routeList = [];
    const routes = this.$router.options.routes[0].children[0].children;
    routes.forEach(element => {
      element.children.forEach(e => {
        routeList.push(`${element.path}/${e.path}`);
      });
    });
    this.flag = true;
    try {
      const res = await fetchPermission('iot');
      const flag = Object.keys(res).some(
        element => routeList.indexOf(element) > -1,
      );
      if (res['/deviceManagement']) {
        flag ? (this.flag = true) : (this.flag = false);
      } else {
        this.flag = false;
      }
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.forbidden {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  :global {
    .x-error-page--type-404 .x-error-page--icon {
      width: 641px;
      height: 370px;
      background-image: url('../assets/images/403.png');
    }
    .x-error-page--main-tip {
      margin-top: 65px;
    }
  }
}
</style>
