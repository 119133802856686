<template>
  <span class="tc-closeable-message">
    <span>{{ content }}</span>
    <x-icon @click="$emit('close')" type="close" />
  </span>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component()
export default class CloseableMessage extends Vue {
  @Prop() content;
}
</script>
<style lang="less">
.tc-closeable-message {
  > .anticon-close {
    color: var(--font);
    font-size: 14px;
    cursor: pointer;
    margin-right: 0;
    margin-left: 4px;
    padding: 4px;
    &:hover {
      color: var(--primary);
    }
  }
}
</style>
