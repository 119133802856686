<template>
  <icon-font :type="type" @click="click" />
</template>
<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Icon } from 'ant-design-vue';

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3713261_8bw2knda9dx.js',
});
@Component({
  components: {
    IconFont,
  },
})
export default class AliIcon extends Vue {
  @Prop({ type: String, required: true }) type;
  @Emit()
  click() {}
}
</script>
<style lang="less" module></style>
