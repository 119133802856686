/** @name 校验数字为小数点后两位 */
export function numberRegular(result) {
  let value = result;
  value = value + '';
  value = value.replace(/[^\d.]/g, ''); //清除"数字"和"."以外的字符
  value = value.replace(/^\./g, ''); //验证第一个字符是数字而不是.
  value = value.replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的.
  value = value
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.'); //只允许输入一个小数点
  value = value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2'); //只能输入两个小数
  return value;
}

/** @name 获取一维路由 */
export function getRoutes(routes) {
  let results = [];
  for (let i = 0, l = routes.length; i < l; i++) {
    if (routes[i].children) {
      results = results.concat(getRoutes(routes[i].children));
    } else {
      results.push(routes[i]);
    }
  }
  return results;
}

/** @name 获取匹配的路由 */
export function findMatchRouteByTargetlist(routes, url, target) {
  const results = routes.filter(v => v.meta && v.meta[target] === true);
  return results.find(v => {
    const path = v.path.match(/[/A-Za-z0-9]+/)[0];
    const lowerUrl = url.toLocaleLowerCase();
    return lowerUrl.includes(path.toLocaleLowerCase());
  });
}

/**
 * @name 获取当前设备的终端类型-默认是Mobile
 * @param { String } type 设备类型
 * 设备类型-移动-Mobile
 * 设备类型-平板-Tablet
 */
export function matchDevice(type = 'Mobile') {
  const u = window.navigator.userAgent;
  const match = {
    //设备
    Mobile:
      u.indexOf('Mobi') > -1 || u.indexOf('iPh') > -1 || u.indexOf('480') > -1,
    Tablet:
      u.indexOf('Tablet') > -1 ||
      u.indexOf('Pad') > -1 ||
      u.indexOf('Nexus 7') > -1,
  };

  return match[type];
}
