import request from '../request';
import { stringify } from '@triascloud/utils';

const basePath = '/tenant-center/tenant/member';

export const getCurrentUser = () => request(basePath);

export const memberList = sendData =>
  request(`${basePath}/list`, {
    method: 'POST',
    body: sendData,
  });

export const getDeptMemberDetails = memberId =>
  request(`${basePath}/enterpriseMemberInfo/${memberId}`);

export const addDeptMember = formData =>
  request(`${basePath}/addDeptMember`, {
    method: 'POST',
    body: formData,
  });

/**
 * @name 编辑当前企业指定用户信息
 * @param formData
 * @returns {*}
 */
export const editDeptMember = formData =>
  request(`${basePath}/deptMember/edit`, {
    method: 'PUT',
    body: formData,
  });

export const getUserByDepart = deptId =>
  request(`${basePath}/memberList/dept/${deptId}`, {
    body: { deptId },
  });

export const roleMember = sendData =>
  request(`${basePath}/roleMember/add`, {
    method: 'POST',
    body: sendData,
  });

export const deleteRoleMember = sendData =>
  request(`${basePath}/deleteRoleMember`, {
    method: 'POST',
    body: sendData,
  });

/** @name 停用用户-离职用户 */
export const disableMember = ({ memberId, memberStatus }) =>
  request('/tenant-center/address/disable', {
    method: 'POST',
    body: {
      memberId,
      memberStatus,
    },
  });

/** @name 异常人员列表 */
export const getAbnormalList = ({ current, size }) =>
  request('/tenant-center/address/AbnormalList', {
    body: {
      current,
      size,
    },
  });

/**
 * @name 删除异常人员
 * @param memberId
 * @returns {*}
 */
export const deleteMember = memberId =>
  request(`${basePath}/deleteBinding?memberId=${memberId}`, {
    method: 'DELETE',
  });

/**
 * @name 获取未激活用户列表
 */
export const getInactiveList = ({
  pkId,
  activation,
  query: { current, size },
}) =>
  request(`${basePath}/memberList/activation`, {
    method: 'POST',
    body: {
      pkId,
      activation,
      query: {
        current,
        size,
      },
    },
  });

/**
 * @name 批量发送激活通知
 * @param {array} memberIds 用户列表
 */
export const sendMsgActivation = memberIds =>
  request(`${basePath}/activation/sendMsg`, {
    method: 'POST',
    body: memberIds,
  });

export const batchImportMember = file => {
  const form = new FormData();
  form.append('file', file);
  return request(`${basePath}/import`, {
    method: 'POST',
    body: form,
  });
};

/**
 * @name 编辑用户
 */
export const editMember = params =>
  request(`${basePath}/edit`, {
    method: 'POST',
    body: params,
  });
/**
 * @name 修改邮箱
 * @param {number} params.type 0-新增 1-编辑
 */
export const editEmail = params =>
  request(`${basePath}/editEmail`, {
    method: 'POST',
    body: params,
  });
/**
 * @name 校验邮箱
 */
export const checkEmail = email =>
  request(`${basePath}/checkEmail`, {
    method: 'GET',
    body: email,
  });
/**
 * @name 修改密码
 * @param {number} params.type 0-新增 1-编辑
 */
export const editPassword = params =>
  request(`${basePath}/editPassword`, {
    method: 'POST',
    body: params,
  });
/**
 * @name 校验密码
 */
export const validationPassword = password =>
  request(`${basePath}/validationPassword/${password}`, {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    method: 'POST',
  });
/**
 * @name 修改手机号
 */
export const editMobile = params =>
  request(`${basePath}/editPhone`, {
    method: 'POST',
    body: params,
  });
/**  @name 校验账号 */
export const checkAccount = account =>
  request(`${basePath}/checkAccount`, {
    method: 'GET',
    body: { account },
  });
/**  @name 添加账号 */
export const saveAccount = params =>
  request(`${basePath}/saveAccount`, {
    method: 'POST',
    body: params,
  });
/**
 * @name 成员名称校验
 * @param {Array} names 成员名称数组
 */
export function memberNameCheck(names) {
  return request(`${basePath}/nameCheck`, {
    method: 'POST',
    body: names,
  });
}

/**
 * @phone 获取手机号基础信息
 * @param {Object} phone 成员名称数组
 */
export function memberData(phone) {
  return request(`${basePath}/baseInfoByPhone`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: stringify(phone),
  });
}

/**
 * @phone 获取成员申请列表
 * @param {Object} page 成员名称数组
 */
export function applyMemberData(page) {
  return request(`${basePath}/applicationList`, {
    method: 'POST',
    body: page,
  });
}

/**
 * @phone 同意加入企业
 * @param {Array} data 成员id集合
 */
export function agreeJoin(data) {
  return request(`${basePath}/tenantJoin/agree`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @phone 拒绝加入企业
 * @param {Array} data 成员id集合
 */
export function unAgreeJoin(data) {
  return request(`${basePath}/tenantJoin/reject`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @phone 获取未激活成员列表
 * @param {Object} page 分页信息
 */
export function getUnInactive(page) {
  return request(`${basePath}/memberList/activation`, {
    method: 'POST',
    body: page,
  });
}
