import request from '../request';

const basePath = '/tenant-center';

export const getOrganizationTree = () =>
  request(`${basePath}/department/organizationTree`);

export const saveOrUpdate = formData =>
  request(`${basePath}/department/saveOrUpdate`, {
    method: 'POST',
    body: formData,
  });

export const getDepartment = deptPkId =>
  request(`${basePath}/department/getDepartment`, {
    body: {
      deptPkId,
    },
  });

export const editMemberDepartment = ({
  currentDepartment,
  memberIds,
  targetDepartment,
}) =>
  request(`${basePath}/department/editMemberDepartment`, {
    method: 'POST',
    body: {
      currentDepartment,
      memberIds,
      targetDepartment,
    },
  });

export function departmentList(data) {
  return request(`${basePath}/department/list`, {
    method: 'GET',
    body: data,
  });
}

export const delDepartment = sendData =>
  request(`${basePath}/department/deleteBatch`, {
    method: 'POST',
    body: sendData,
  });
/**
 * @name 部门名称校验
 * @param {Array} nameArray 部门名称数组
 */
export function departmentNameCheck(nameArray) {
  return request(`${basePath}/department/nameCheck`, {
    method: 'POST',
    body: nameArray,
  });
}
/**
 * @name 用户ID集合获取对应部门集合
 * @param {Array} memberIds 用户ID集合
 */
export function listDeptByMemberId(memberIds) {
  return request(`${basePath}/department/listDeptByMemberId`, {
    method: 'POST',
    body: memberIds,
  });
}

/**
 * @name 获取用户在该企业权限
 *
 */
export function getMemberAuthority() {
  return request(`${basePath}/adminAuthorityMember/getMemberAuthority`, {
    method: 'GET',
  });
}

export function getCurrentDepartmentList() {
  return request(`${basePath}/department/current`, {
    method: 'GET',
  });
}

/**
 * @name 获取当前用户企业是否开通企业物联网SD110
 */
export function getIotStatus() {
  return request(`${basePath}/tenant/iotStatus`, {
    method: 'GET',
  });
}
