<template>
  <div :class="$style.main">
    <global-header @click.native="handleHideMenu">
      <template slot="left">
        <menu-drawer
          ref="menu"
          base-path="/production"
          :origin="origin"
          :key="tenantId"
          :auto-add-recent="false"
          :custom-url="createCustomUrl"
          @data-change="updateMenuData"
        />
        <company-select
          :data="companyList"
          :value="tenantId"
          @change="handleCompanyChange"
          v-if="tenant"
        />
        <div
          v-else
          :class="$style.noCompany"
          @click="handleShowOperationSelect"
        >
          <x-icon type="tc-icon-company" :class="$style.noCompanyIcon" />
          <span :class="$style.noCompanyTip">加入 / 创建企业</span>
        </div>
      </template>
      <global-header-right :key="tenantId" slot="right" />
    </global-header>
    <router-view :key="tenantId" :class="$style.content" />
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  Header as GlobalHeader,
  MenuDrawer,
  CompanySelect,
} from '@triascloud/x-blocks';
import { createModal } from '@triascloud/x-components';
import GlobalHeaderRight from './components/global-header-right';
import { crossStorageModule, globalModule } from '@/enum/store.js';
import { switchEnterprise } from '@/services/tenant-center/enterprise';
import { createModuleUrl } from '@/utils';
import { XOperationSelect } from '@/components/x-operation-select';
import { getIotStatus } from '@/services/tenant-center/department';

@Component({
  components: {
    GlobalHeader,
    GlobalHeaderRight,
    MenuDrawer,
    CompanySelect,
  },
})
export default class MainLayout extends Vue {
  @globalModule.State companyList;
  @globalModule.State refreshMenuFlag;
  @crossStorageModule.State tenant;
  @crossStorageModule.Mutation setItem;
  @globalModule.Action refreshCompanyList;
  @globalModule.Action refreshMenu;
  @globalModule.Action refreshUser;
  @globalModule.Mutation updateMenuData;
  get origin() {
    return createModuleUrl('ds');
  }
  get tenantId() {
    return this.tenant ? this.tenant.pkId : 'VISITOR';
  }
  handleShowOperationSelect() {
    const modal = createModal(
      () => (
        <XOperationSelect
          onRefreshData={() => {
            this.handleRefreshData();
            modal.handleClosed();
          }}
        />
      ),
      {
        title: '选择操作',
        width: '730px',
        className: 'x-operation-select-modal',
      },
    );
  }
  async handleCompanyChange(company) {
    // 先等待切换公司的接口执行完成
    await switchEnterprise(company.pkId);
    // 执行完成后后端的数据已经是最新的公司
    await Promise.all([
      // 刷新用户信息
      this.refreshUser(),
      // 刷新权限信息
      this.$router.permission.init(true),
    ]);
    const currentPath = this.$route.fullPath;
    localStorage.setItem(`path.companySwitch.${this.tenantId}`, currentPath);
    const nextPath = localStorage.getItem(`path.companySwitch.${company.pkId}`);
    let path = nextPath || '/';
    // 如果跳转的路由与当前路由相同，需要手动检测一次权限
    if (path === currentPath) {
      path = await this.$router.resolvePermission(path);
    }
    try {
      if (path !== currentPath) await this.$router.replace(path);
      else {
        const noPermissionPath = '/noPermission';
        const permission = await getIotStatus();
        !permission &&
          path !== noPermissionPath &&
          (await this.$router.replace(noPermissionPath));
      }
    } finally {
      this.setItem({
        key: 'tenant',
        value: JSON.stringify(company),
      });
    }
  }
  handleHideMenu() {
    if (this.$refs.menu.menusDrawerVisible) {
      this.$refs.menu.handleMenusDrawerClose();
    }
  }

  createCustomUrl(menu) {
    if (menu.moduleId && menu.menuId) {
      return `${createModuleUrl(
        menu.moduleId === 'iot' ? 'iot-max' : menu.moduleId,
      )}${menu.menuId}`;
    } else if (menu.menuId) {
      return `${createModuleUrl('app')}/form/${menu.menuId}`;
    } else {
      return menu.uri;
    }
  }

  handleRefreshData() {
    this.refreshCompanyList();
  }
  @Watch('refreshMenuFlag')
  handleRefreshMenu(flag) {
    if (!flag) return;
    this.$refs.menu.loadMenu();
  }

  created() {
    this.refreshCompanyList();
  }
}
</script>
<style lang="less" module>
.content {
  height: calc(100% - 56px);
  overflow-x: hidden;
  overflow-y: auto;
}
.noCompany {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.noCompanyIcon {
  font-size: 24px;
  margin-right: 10px;
  color: white;
  padding: 6px;
  background-color: var(--primary);
  border-radius: 50%;
}
.noCompanyTip {
  font-size: 16px;
  font-family: Microsoft YaHei, serif;
  font-weight: 400;
  color: var(--font);
}
</style>
