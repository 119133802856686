<template>
  <div :class="$style.layout">
    <div
      :class="$style.background"
      :style="{
        backgroundImage: `url(${background})`,
      }"
    >
      <div :class="$style.header">
        <img :src="headerBackground" alt :class="$style.headerBackground" />
        <div :class="$style.headerContent">
          <slot name="headerLeft" />
          <div :class="$style.headerMiddle">
            <div :class="$style.headerMiddleContent">
              <slot name="headerMiddle" />
            </div>
            <div :class="$style.headerActions">
              <slot name="headerAction" />
            </div>
          </div>
          <div :class="$style.headerRight">
            <div :class="$style.actionIcons">
              <skin-select :class="$style.actionIcon" />
              <ali-icon
                :class="$style.actionIcon"
                :type="isFullScreen ? 'tc-icon-suofang' : 'tc-icon-zhankai'"
                @click="handleFullScreen"
              />
            </div>
            <div :class="$style.clock">
              <div :class="$style.time">{{ time }}</div>
              <div :class="$style.date">{{ date.date }}</div>
            </div>
            <!-- <div>星期{{ date.week }}</div> -->
          </div>
        </div>
      </div>
      <div :class="$style.content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { crossStorageModule } from '@/enum/store';
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import AliIcon from '@/components/ali-icon/index.vue';
import screenfull from '@/views/screen/components/full-screen';
import { Emitter } from '@/views/screen/components/screen-utils';
import { flexDpr } from '@/assets/js/flex-dpr.js';
import SkinSelect from './skin-select.vue';

flexDpr();
@Component({
  components: { AliIcon, SkinSelect },
})
export default class Layout extends Vue {
  @crossStorageModule.Mutation setItem;
  @Prop({
    type: String,
    default: require('@/assets/images/theme/background.jpg'),
  })
  background;
  @Prop({
    type: String,
    default: require('@/assets/images/theme/header.png'),
  })
  headerBackground;
  get date() {
    const now = moment(this.now);
    return {
      week: { 1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六', 7: '日' }[
        now.format('e')
      ],
      date: now.format('YYYY.MM.DD'),
    };
  }
  get time() {
    return moment(this.now).format('HH:mm:ss');
  }

  interval = 0;
  now = Date.now();
  isFullScreen = false;
  handleFullScreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
      this.isFullScreen = !this.isFullScreen;
      Emitter.$emit('fullscreen');
    }
  }
  beforeDestroy() {
    clearInterval(this.interval);
  }
  created() {
    this.interval = setInterval(() => {
      this.now = Date.now();
    }, 1000);
    // this.setItem({
    //   key: 'skin',
    //   value: JSON.stringify({ mode: 'dark', theme: 'azure' }),
    // });
  }
}
</script>
<style module lang="less">
.layout {
  position: relative;
  height: 100vh;
  --font: #fff;

  @font-face {
    font-family: YouSheBiaoTiHei;
    src: url('~@/assets/fonts/YouSheBiaoTiHei.ttf') format('truetype');
  }
  .background {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .customBackground {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  .content {
    flex: 1;
    overflow: hidden;
  }
}
.header {
  position: relative;
  z-index: 10;
  .headerBackground {
    width: 100%;
    height: auto;
  }
  .headerContent {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    padding: 0 0.6rem;
    height: 100%;
    .headerRight {
      display: flex;
      align-items: center;
      .actionIcons {
        margin-right: 0.3rem;
        color: var(--screen-font-3);
        .actionIcon {
          border-radius: 50%;
          width: 0.32rem;
          height: 0.32rem;
          line-height: 0.32rem;
          margin-left: 0.2rem;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.2);
          text-align: center;
          font-size: 0.16rem;
          cursor: pointer;
        }
      }
      .clock {
        color: var(--screen-font-3);
        .time {
          font-size: 0.32rem;
          font-weight: bold;
        }
        .date {
          transform: translateY(-0.08rem);
          font-size: 0.16rem;
        }
      }
    }
    .headerMiddle {
      display: flex;
      flex: 1;
      padding: 0 0.32rem;
      margin-top: 0.38rem;
      justify-content: flex-end;
      align-items: center;
      height: 0.4rem;
      .headerMiddleContent {
        flex: 1;
      }
      .headerActions {
        display: flex;
        .actionIcon {
          border-radius: 50%;
          width: 0.32rem;
          height: 0.32rem;
          line-height: 0.32rem;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.2);
          text-align: center;
          font-size: 0.8em;
          cursor: pointer;
        }
        & > * + {
          margin-left: 0.2rem;
        }
      }
    }
  }
}
:global {
  .screen-modal {
    --font: #fff;
    .ant-modal-content {
      background: rgba(0, 33, 51, 0.9);
      box-shadow: var(--screen-box-shadow);
      border: 1px solid var(--screen-box-border-color);
      border-radius: 0.04rem;
      .ant-modal-header {
        border-radius: 0;
        padding: 10px 16px;
        border-bottom: none;
        .ant-modal-title {
          font-size: 0.18rem;
          font-weight: 800;
          color: #ffffff;
        }
      }
    }
    .ant-table {
      --table-row-hover: transparent;
      .ant-table-thead {
        th {
          background: transparent;
          color: #9ac0d9;
        }
      }
      .ant-table-tbody > tr {
        &:hover {
          background: linear-gradient(
            270deg,
            #76c5ff94 0%,
            rgba(118, 197, 255, 0.06) 41%,
            rgba(118, 197, 255, 0.06) 60%,
            #76c5ff94 100%
          );
        }
        td {
          background: transparent;
        }
      }
    }
  }
}
</style>
