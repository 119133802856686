<template>
  <a-popover
    trigger="click"
    placement="bottom"
    :overlayClassName="$style.skinBox"
  >
    <div slot="content" :class="$style.skinList">
      <div
        :class="$style.skinItem"
        v-for="(item, index) in skins"
        :key="index"
        @click.stop="handleChangeSkin(item, index)"
      >
        <a-icon
          type="check"
          :class="$style.check"
          v-if="index === selectedIndex"
        />
        <div
          :class="$style.itemBg"
          :style="{ backgroundColor: item.color }"
        ></div>
        <div :class="$style.itemName">{{ item.name }}</div>
      </div>
    </div>
    <a-icon type="skin" :class="$style.actionIcon" />
  </a-popover>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

const skins = [
  {
    name: '科技蓝',
    color: '#30A0CC',
    theme: 'blue',
  },
];
@Component()
export default class SkinSelect extends Vue {
  skins = skins;
  selectedIndex = 0;
  handleChangeSkin(item, index) {
    this.selectedIndex = index;
    // const body = document.body;
    // body.setAttribute('class', 'theme2');
  }
}
</script>
<style module lang="less">
.skinBox {
  top: 52px !important;
  .skinList {
    max-width: 2.2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .skinItem {
      text-align: center;
      position: relative;
      margin-bottom: 0.08rem;
      .itemBg {
        height: 0.64rem;
        width: 0.64rem;
        border-radius: 0.04rem;
        cursor: pointer;
      }
      .itemName {
        font-size: 0.14rem;
        color: #333333;
      }
      .check {
        position: absolute;
        font-size: 0.14rem;
        color: #fff;
        left: 0.04rem;
        top: 0.04rem;
      }
    }
  }
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner-content {
      background-color: #fff;
      border-radius: 0.06rem;
      padding: 0.12rem 0.12rem 0.04rem;
    }
  }
}
</style>
