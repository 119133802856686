// @ts-check
import { message } from 'ant-design-vue';
// @ts-ignore
import CloseableMessage from './closeable-message.vue';

/** @typedef { import('ant-design-vue/types/message').Message } Message */

/** @typedef { import('ant-design-vue/types/message').MessageType } MessageType */

/** @typedef { import('ant-design-vue/types/message').ConfigDuration } ConfigDuration */

/** @typedef { import('ant-design-vue/types/message').ConfigOnClose } ConfigOnClose */

/** @typedef { import('ant-design-vue/types/message').ConfigType } ConfigType */

/** @typedef { import('ant-design-vue/types/message').MessageOptions } MessageOptions */

/** @type { Message } */
const closeableMessage = {
  ...message,
  open: ({ content, ...options }) => {
    /** @type { MessageType } */
    let close = () => {};
    let closed = false;
    const promise = new Promise(resolve => {
      const originClose = message.open({
        ...options,
        onClose: () => {
          closed = true;
          resolve(false);
          options.onClose && options.onClose();
        },
        content: h =>
          h(CloseableMessage, {
            on: { close },
            props: { content },
          }),
      });
      // @ts-ignore
      close = () => {
        if (closed) return;
        originClose();
        closed = true;
        resolve(true);
        options.onClose && options.onClose();
      };
    });
    close.then = promise.then.bind(promise);
    close.promise = promise;
    return close;
  },
};

/** @type { ['success', 'info', 'warning', 'error'] } */
const type = ['success', 'info', 'warning', 'error'];

/**
 *
 * @param { unknown } input
 * @returns { input is MessageOptions }
 */
const isMessageOptions = input =>
  !!(input && typeof input === 'object' && 'content' in input);

type.forEach(type => {
  /**
   * @param { ConfigType | MessageOptions } content
   * @param { ConfigDuration= } duration
   * @param { ConfigOnClose= } onClose
   * @returns { MessageType }
   */
  closeableMessage[type] = (content, duration, onClose) => {
    if (isMessageOptions(content)) {
      return closeableMessage.open({
        ...content,
        type,
      });
    } else {
      if (typeof duration === 'function') {
        onClose = duration;
        duration = undefined;
      }
      return closeableMessage.open({
        content,
        duration,
        type,
        onClose,
      });
    }
  };
});

export default closeableMessage;
