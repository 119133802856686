import {
  getScreenMenuList,
  getOrganizationTreeList,
} from '@/services/screen/screen';
const screen = {
  namespaced: true,
  state: {
    screenMenus: [],
    treeList: [],
    projectId: '',
    token: '',
    region: {
      city: '',
      cityCode: '',
      province: '',
      provinceCode: '',
    },
  },
  mutations: {
    updateMenus(state, data) {
      state.screenMenus = data;
    },
    updateTreeList(state, data) {
      state.treeList = data;
    },
    updateProjectId(state, projectId) {
      state.projectId = projectId;
    },
    updateToken(state, token) {
      state.token = token;
    },
    updateRegion(state, region) {
      state.region = region;
    },
  },
  actions: {
    async updateScreenMenus({ commit }, params) {
      try {
        const data = await getScreenMenuList(params);
        commit('updateMenus', data);
      } catch {
        commit('updateMenus', []);
      }
    },
    async updateTreeList({ commit }) {
      try {
        const data = await getOrganizationTreeList();
        commit('updateTreeList', data);
      } catch {
        commit('updateTreeList', []);
      }
    },
  },
};

export default screen;
