<template>
  <a-popover
    v-model="popoverFlag"
    trigger="click"
    placement="bottom"
    :overlayClassName="$style.searchProject"
    :destroyTooltipOnHide="true"
    @visibleChange="visibleChange"
  >
    <template slot="content">
      <div>
        <a-input-search
          style="margin-bottom: 8px;color: var(--screen-font-3);"
          placeholder="搜索公司/项目名称"
          @change="onSearch"
        />
        <a-tree
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :tree-data="treeList"
          @expand="onExpand"
          @select="onSelect"
        >
          <template slot="title" slot-scope="{ title }">
            <span v-if="title.indexOf(searchValue) > -1">
              {{ title.substr(0, title.indexOf(searchValue)) }}
              <span style="color: #f50">{{ searchValue }}</span>
              {{
                title.substr(title.indexOf(searchValue) + searchValue.length)
              }}
            </span>
            <span v-else>{{ title }}</span>
          </template>
        </a-tree>
      </div>
    </template>
    <div :class="$style.project" @click.stop="popoverFlag = !popoverFlag">
      <div :class="$style.projectName">{{ projectName }}</div>
      <div :class="$style.iconBox">
        <ali-icon
          type="tc-icon-xiala"
          :class="[$style.projectIcon, openSearch ? $style.changeRote : '']"
        />
      </div>
    </div>
  </a-popover>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon';

const getParentKey = (key, tree) => {
  let parentKey;
  tree.forEach(node => {
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  });
  return parentKey;
};
@Component({
  components: {
    AliIcon,
  },
})
export default class SearchProject extends Vue {
  created() {
    this.getOrganizationTree();
  }
  // 项目搜索
  popoverFlag = false;
  openSearch = false;
  visibleChange(flag) {
    this.openSearch = flag;
  }
  projectName = '';
  groupId = '';
  // 获取组织架构
  treeList = [];
  getOrganizationTree() {
    try {
      const data = this.$store.state.screen.treeList;
      let treeObj = {};
      /** 存在总公司 */
      if (data.headGroupOrgVO) {
        const obj = data.headGroupOrgVO;
        // type: 1项目 2公司
        treeObj = {
          title: obj.organizationName,
          key: obj.groupId,
          groupId: obj.groupId,
          type: '2',
          region: {
            city: obj.city,
            cityCode: obj.cityCode,
            province: obj.province,
            provinceCode: obj.provinceCode,
          },
          scopedSlots: {
            title: 'title',
          },
          children: [],
        };
        obj.currentBIList.forEach(item => {
          treeObj.children.push({
            title: item.projectName,
            key: item.pkId,
            groupId: obj.groupId,
            type: '1',
            scopedSlots: {
              title: 'title',
            },
          });
        });
        if (data.projectGroupBIList.length) {
          data.projectGroupBIList.forEach(item => {
            if (item.currentBIList.length) {
              item.currentBIList.forEach(childItem => {
                treeObj.children.push({
                  title: childItem.projectName,
                  key: childItem.pkId,
                  groupId: item.groupId,
                  type: '1',
                  scopedSlots: {
                    title: 'title',
                  },
                });
              });
            }
          });
        }
        if (data.smallGroupOrgVOList.length) {
          data.smallGroupOrgVOList.forEach(item => {
            if (item.currentBIList.length) {
              treeObj.children.push({
                title: item.organizationName,
                key: item.groupId,
                groupId: item.groupId,
                type: '2',
                region: {
                  city: item.city,
                  cityCode: item.cityCode,
                  province: item.province,
                  provinceCode: item.provinceCode,
                },
                scopedSlots: {
                  title: 'title',
                },
                children: item.currentBIList.map(childItem => ({
                  title: childItem.projectName,
                  key: childItem.pkId,
                  groupId: item.groupId,
                  type: '1',
                  scopedSlots: {
                    title: 'title',
                  },
                })),
              });
            } else {
              treeObj.children.push({
                title: item.organizationName,
                key: item.groupId,
                groupId: item.groupId,
                type: '2',
                region: {
                  city: item.city,
                  cityCode: item.cityCode,
                  province: item.province,
                  provinceCode: item.provinceCode,
                },
                scopedSlots: {
                  title: 'title',
                },
              });
            }
          });
        }
        // 初始化项目名称、编组id和项目id
        this.projectName = obj.currentBIList[0].projectName;
        this.groupId = obj.groupId;
        this.$store.commit('screen/updateProjectId', obj.currentBIList[0].pkId);
        this.treeList.push(treeObj);
      } else {
        /** 不存在总公司 */
        if (data.projectGroupBIList.length) {
          data.projectGroupBIList.forEach(item => {
            if (item.currentBIList.length) {
              item.currentBIList.forEach(childItem => {
                this.treeList.push({
                  title: childItem.projectName,
                  key: childItem.pkId,
                  groupId: item.groupId,
                  type: '1',
                  scopedSlots: {
                    title: 'title',
                  },
                });
              });
            }
          });
        }
        if (data.smallGroupOrgVOList.length) {
          data.smallGroupOrgVOList.forEach(item => {
            if (item.currentBIList.length) {
              this.treeList.push({
                title: item.organizationName,
                key: item.groupId,
                groupId: item.groupId,
                type: '2',
                region: {
                  city: item.city,
                  cityCode: item.cityCode,
                  province: item.province,
                  provinceCode: item.provinceCode,
                },
                scopedSlots: {
                  title: 'title',
                },
                children: item.currentBIList.map(childItem => ({
                  title: childItem.projectName,
                  key: childItem.pkId,
                  groupId: item.groupId,
                  type: '1',
                  scopedSlots: {
                    title: 'title',
                  },
                })),
              });
            } else {
              this.treeList.push({
                title: item.organizationName,
                key: item.groupId,
                groupId: item.groupId,
                type: '2',
                region: {
                  city: item.city,
                  cityCode: item.cityCode,
                  province: item.province,
                  provinceCode: item.provinceCode,
                },
                scopedSlots: {
                  title: 'title',
                },
              });
            }
          });
        }
        // 初始化项目名称、编组id和项目id
        if (this.treeList[0].children) {
          this.projectName = this.treeList[0].children[0].title;
          this.groupId = this.treeList[0].groupId;
          this.$store.commit(
            'screen/updateProjectId',
            this.treeList[0].children[0].key,
          );
        } else {
          this.projectName = this.treeList[0].title;
          this.groupId = this.treeList[0].groupId;
          this.$store.commit('screen/updateProjectId', this.treeList[0].key);
        }
      }
      this.generateList(this.treeList);
    } catch (err) {
      this.treeList = [];
    }
  }
  keyList = [];
  generateList(data) {
    data.forEach(item => {
      const key = item.key;
      this.expandedKeys.push(item.key);
      this.keyList.push({
        key,
        title: item.title,
        type: item.type,
        groupId: item.groupId,
        region: item.region,
      });
      if (item.children) {
        this.generateList(item.children);
      }
    });
  }
  expandedKeys = [];
  searchValue = '';
  autoExpandParent = true;
  onExpand(expandedKeys) {
    this.expandedKeys = expandedKeys;
    this.autoExpandParent = false;
  }
  onSearch(e) {
    const value = e.target.value;
    const expandedKeys = this.keyList
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, this.treeList);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    Object.assign(this, {
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  }
  onSelect(v) {
    const id = v[0];
    this.$store.commit('screen/updateProjectId', id);
    const obj = this.keyList.find(item => item.key === id);
    // 保存省和市的名称和编码，用于大屏地图的区域跳转
    this.$store.commit('screen/updateRegion', obj.region);
    this.projectName = obj.title;
    const params = {
      groupId: obj.groupId,
      configType: obj.type,
      projectName: this.projectName,
    };
    this.$emit('select', params);
    this.popoverFlag = false;
    this.openSearch = false;
  }
}
</script>

<style lang="less" module>
.project {
  display: flex;
  align-items: center;
  width: 3.4rem;
  overflow: hidden;
  cursor: pointer;
  .projectName {
    font-size: 0.4rem;
    max-width: 3rem;
    overflow-x: hidden;
    overflow-y: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: YouSheBiaoTiHei;
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    background: linear-gradient(
      148deg,
      #ffffff 18%,
      #d7e9ff 38%,
      #d7e9ff 69%,
      #ffffff 90%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .iconBox {
    width: 0.2rem;
    height: 0.2rem;
    background: rgba(1, 94, 144, 0.5);
    box-shadow: inset 0px -4px 6px 0px #1d8eba;
    border: 1px solid;
    border-image: linear-gradient(
        360deg,
        rgba(150.83535343408585, 177.73290932178497, 209.61000680923462, 1),
        rgba(166.27333223819733, 194.38203513622284, 227.7405059337616, 1)
      )
      1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.16rem;
    .projectIcon {
      cursor: pointer;
      font-size: 0.14rem;
      transition: all 0.3s;
      color: var(--font);
    }
  }

  .changeRote {
    transform: rotate(180deg);
  }
}
.searchProject {
  width: 3rem;
  .searchProjectInput {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    :global {
      .ant-input-search-icon {
        color: #fff;
      }
      .ant-input {
        border: 1px solid rgba(255, 255, 255, 0.2);
        &::-webkit-input-placeholder {
          color: #fff;
        }
      }
    }
  }
  :global {
    .ant-popover-arrow {
      color: var(--screen-font-3);
      display: none;
    }
    .ant-popover-inner {
      background: linear-gradient(
        180deg,
        var(--screen-nav-bg) 59%,
        var(--screen-nav-active-bg) 100%
      );
    }
    .ant-tree .ant-tree-title {
      color: var(--screen-font-3);
    }
    .ant-tree-node-content-wrapper {
      width: 80%;
    }
    .ant-input {
      color: var(--screen-font-3);
    }
  }
  .searchProjectItem {
    color: #fff;
    margin-top: 0.16rem;
    cursor: pointer;
  }
}
</style>
