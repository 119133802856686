import { CrossStorageEventType } from '@triascloud/cross-storage';
import { SSOClient } from '@triascloud/sso-client';
function execFilter(key, filter) {
  if (Array.isArray(filter)) {
    return filter.some(item => execFilter(key, item));
  } else if (typeof filter === 'string') {
    return filter === key;
  } else if (filter instanceof RegExp) {
    return filter.test(key);
  } else {
    return filter(key);
  }
}
/**
 * @type {import { CrossStoragePlatform } from '@triascloud/cross-storage';}
 */
export class CrossStorageMemory {
  constructor(options) {
    const defaultOptions = {
      storage: localStorage,
      // disabledKeys: [/^i18n\./, /^services\.iconLibrary/],
      allowKeys: [
        SSOClient.TOKEN_KEY,
        SSOClient.EXPIRED_KEY,
        'locale',
        'tenant',
        'user',
        'skin',
      ],
      loadExtraStorage: () => null,
    };
    Object.assign(defaultOptions, options);
    this.options = defaultOptions;
  }
  get disabledKeys() {
    return this.options.disabledKeys;
  }
  get allowKeys() {
    return this.options.allowKeys;
  }
  get storage() {
    return this.options.storage;
  }

  async connect(receiveMessageCallback) {
    await this.receive();
    window.addEventListener('storage', ev =>
      receiveMessageCallback({
        type: CrossStorageEventType.Update,
        data: {
          key: ev.key,
          value: ev.newValue,
        },
        __CROSS_STORAGE__: true,
      }),
    );
    receiveMessageCallback({
      type: CrossStorageEventType.Init,
      __CROSS_STORAGE__: true,
      data: this.getAllStorage(),
    });
    this.extendToken(receiveMessageCallback);
  }
  /** @name 每分钟延长一次token有效期 */
  extendToken(receiveMessageCallback) {
    const token = this.storage.getItem(SSOClient.TOKEN_KEY);
    if (!token) return;
    const rememberMe = localStorage.getItem('storageType') === 'localStorage';
    const expired = Date.now() + (rememberMe ? 7 * 24 : 2) * 3600 * 1000;
    localStorage.setItem(SSOClient.EXPIRED_KEY, expired);
    receiveMessageCallback({
      type: CrossStorageEventType.Update,
      data: {
        key: SSOClient.EXPIRED_KEY,
        value: expired,
      },
    });
    setTimeout(() => this.extendToken(receiveMessageCallback), 60 * 1000);
  }
  /** @name 客户端事件处理 */
  send(payload) {
    if (!payload.__CROSS_STORAGE__) return;
    const { type, data } = payload || {};
    switch (type) {
      case CrossStorageEventType.Update:
        this.storage.setItem(data.key, data.value);
        break;
      case CrossStorageEventType.Delete:
        this.storage.removeItem(data);
        break;
      case CrossStorageEventType.Clear:
        this.storage.clear();
        break;
    }
  }
  getAllStorage() {
    return (Array(this.storage.length)
      .fill(null)
      .reduce((map, _, index) => {
        const key = this.storage.key(index);
        if (key && this.filterKey(key)) {
          map[key] = this.storage.getItem(key);
        }
        return map;
      }, {}));
  }
  filterKey(key) {
    const { allowKeys, disabledKeys } = this;
    // 如果同时设置了白名单和黑名单，优先检测白名单
    if (allowKeys && disabledKeys)
      return execFilter(key, allowKeys) || !execFilter(key, disabledKeys);
    // 检测是否在白名单中
    if (allowKeys) return execFilter(key, allowKeys);
    // 检测是否在黑名单中
    if (disabledKeys) return !execFilter(key, disabledKeys);
    // 默认返回true
    return true;
  }
  async receive() {
    // const { loadExtraStorage } = this.options;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get(SSOClient.TOKEN_KEY);
    const storageType = urlSearchParams.get('storageType');
    const redirect = urlSearchParams.get('redirect');
    const close = urlSearchParams.get('close');
    const expiredAt = urlSearchParams.get('expiredAt');
    if (token && storageType) {
      this.storage.setItem('storageType', storageType);
      this.storage.setItem(SSOClient.TOKEN_KEY, token);
      this.storage.setItem(SSOClient.EXPIRED_KEY, expiredAt);
      try {
        const extraStorage = await this.options.loadExtraStorage(token);
        extraStorage &&
          Object.entries(extraStorage).forEach(([key, value]) => {
            this.storage.setItem(key, value);
          });
      } catch (e) {
        // console.error('加载用户信息失败', e);
      } finally {
        this.storage.setItem('storageLoaded', Date.now());
        if (close && window.opener) {
          window.close();
        } else {
          location.replace(redirect || '/');
        }
      }
    }
  }
}
