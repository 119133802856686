export const ErrorCode = {
  /** @name 未登录或登录状态失效 */
  NotLogin: 401,
  /** @name 无权限访问 */
  Forbidden: 403,
  /** @name 有权限但当前企业错误 */
  TenantError: 3014,
  /** @name 需要密码访问 */
  NeedPassword: 50002,
};
