import request from '../request';
import { stringify } from '@triascloud/utils';
const serviceName = '/tenant-center'; // 租户中心
const moduleName = '/enterprise'; // 企业对象管理

const publicPath = serviceName + moduleName;

/**
 * 获取当前用户的组织详细信息集合
 * @returns {*}
 */
export function getCurrentUserEnterpriseDetailList() {
  return request(`${publicPath}/currentUser`, {
    method: 'GET',
  });
}

/**
 * 设置主企业
 * @param targetEnterpriseId
 * @returns {*}
 */
export function setMainEnterprise(targetEnterpriseId) {
  return request(`${publicPath}/mainEnterprise`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: stringify({ targetEnterpriseId }),
  });
}

/**
 * 解散企业
 * @param data
 * @returns {*}
 */
export function disbandEnterprise(data) {
  return request(`${publicPath}/disband`, {
    method: 'DELETE',
    body: data,
  });
}

/**
 * 退出企业
 * @param data
 * @returns {*}
 */
export function leaveEnterprise(data) {
  return request(`${publicPath}/leave`, {
    method: 'DELETE',
    body: data,
  });
}

export function switchEnterprise(tenantId) {
  return request(`${publicPath}/switchEnterprise?${stringify({ tenantId })}`, {
    method: 'PUT',
  });
}

export const inviteByEmail = contact =>
  request(`${publicPath}/inviteMember`, {
    method: 'POST',
    body: contact,
  });

export const getInviteUrl = () => request(`${publicPath}/inviteUrl`);

export const createInviteUrl = () => request(`${publicPath}/random`);

export const stopInviteUrl = () =>
  request(`${publicPath}/stop`, {
    method: 'PUT',
  });

/**
 * @typedef Enterprise
 * @property { string } enterpriseName	企业租户名称
 * @property { string } logo	企业logo
 * @property { boolean } mainEnterprise	是否是主企业
 * @property { string } memberStatus	成员状态
 * @property { string } organizationOrigin	组织架构来源
 * @property { string } pkId	租户企业ID
 * @property { string } tenantStatus	租户企业状态
 */

/**
 * @name 获取当前用户公司简要信息列表
 * @description 用于头部公司列表渲染
 * @returns { Promise<Enterprise[]> }
 */
export function getCurrentTenantInfoList() {
  return request(`${publicPath}/memberEnterprise`, {
    method: 'GET',
  });
}
