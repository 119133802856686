import { safeParseJSON } from '@triascloud/utils';
import { setHeaders } from '@/services/request';
import { setCustomModuleUrl } from '.';
import {
  createDefaultModuleUrl,
  getSettingByDomain,
} from '@/services/template-manager/website';
import { getOssPathWithoutAuth } from '@/services/oss/backend';

function getTopLevelDomain(url) {
  // 移除协议（http, https, ftp）
  url = url.replace(/^https?:\/\//i, '');
  // 移除端口号
  url = url.replace(/:\d+$/, '');
  // 移除www.
  url = url.replace(/^www\./, '');
  // 查找两个点之后的部分作为TLD
  const parts = url.split('.');
  if (parts.length <= 2) {
    // 没有子域名，返回结果
    return url;
  }
  // 有子域名，返回最后两个部分
  const tld = parts.slice(-2).join('.');
  // 特定情况例如国家顶级域名.com.cn或.org.cn
  if (tld.split('.').length === 3) {
    return parts.slice(-3).join('.');
  }
  return tld;
}
export async function checkHost(app = 'app') {
  if (
    location.origin === createDefaultModuleUrl(app) ||
    /localhost|\d+.\d+.\d+/.test(location.origin)
  )
    return false;
  const setting = await getSettingByDomain(location.host);
  if (setting) {
    setHeaders({
      'X-Referer-Token': setting.refererToken || '',
    });
    const liteModeHost = {
      app: setting.pc?.host,
      m: setting.mobile?.host,
      account: `${setting.pc?.host || location.host}/login.html`,
      iot: setting.iot?.host,
      ds: setting.ds?.host,
    };
    setCustomModuleUrl((module, protocol) =>
      liteModeHost[module]
        ? `${protocol}//${liteModeHost[module]}`
        : createDefaultModuleUrl(module, protocol),
    );
    const img = safeParseJSON(setting.domainImg, () => null);
    img?.[0] &&
      getOssPathWithoutAuth(img[0].ossPath).then(res => {
        const $favicon = document.querySelector('link[rel="icon"]');
        $favicon.href = res;
      });
    // 是否为同一个主域
    setting.isSameTopLevelDomain =
      getTopLevelDomain(setting.pc?.host || '') ===
      getTopLevelDomain(location.host);
  }
  return setting;
}
