<template>
  <div :class="$style.globalHeaderRight">
    <a-tooltip :title="$t('common.title.home')">
      <div :class="$style.iconLink" @click="handleRoute('/')">
        <x-icon type="tc-icon-home" />
      </div>
    </a-tooltip>
    <a-tooltip :title="$t('common.title.management')">
      <div :class="$style.iconLink" @click="handleRoute('/application')">
        <x-icon type="tc-icon-application" />
      </div>
    </a-tooltip>
    <a-tooltip :title="$t('common.title.my')">
      <div :class="$style.iconLink" @click="handleRoute('/todo')">
        <x-icon type="tc-icon-todo" />
      </div>
    </a-tooltip>
    <a-tooltip :title="$t('common.title.message')">
      <notice
        ref="notice"
        :more-url="`${createModuleUrl('app')}/message`"
        moreTarget="_blank"
        :handle-click="handleMessageClick"
      />
    </a-tooltip>
    <a-tooltip :title="$t('common.title.skin')">
      <theme-select
        :trigger="['click']"
        :value="skin"
        @input="handleSkinChange"
      />
    </a-tooltip>
    <a-tooltip :title="$t('common.title.download')">
      <download
        :more-url="`${createModuleUrl('app')}/download`"
        moreTarget="_blank"
      />
    </a-tooltip>
    <divider type="vertical" :class="$style.divider" />
    <language-select
      :data="localeList"
      :value="locale"
      @input="handleLocaleChange"
    />
    <user-menu
      :avatar="currentUser.avatar"
      @change="handleUserMenuClick"
      :data="userMenu"
    />
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Divider } from 'ant-design-vue';
import {
  Notice,
  ThemeSelect,
  Download,
  LanguageSelect,
  UserMenu,
} from '@triascloud/x-blocks';
import { crossStorageModule, globalModule } from '@/enum/store.js';
import { ssoClient } from '@triascloud/sso-client';
import { globalVueI18n } from '@triascloud/i18n';
import { createModal } from '@triascloud/x-components';
import { XOperationSelect } from '@/components/x-operation-select';
import { createModuleUrl } from '@/utils';

@Component({
  components: {
    Notice,
    ThemeSelect,
    Download,
    Divider,
    LanguageSelect,
    UserMenu,
  },
})
export default class HeaderRight extends Vue {
  @crossStorageModule.State('user') currentUser;
  @crossStorageModule.State('skin') skin;
  @crossStorageModule.State('tenant') tenant;
  @crossStorageModule.Mutation setItem;
  @globalModule.State refreshMessageFlag;
  @globalModule.Action refreshCompanyList;

  createModuleUrl;
  created() {
    this.createModuleUrl = createModuleUrl;
  }
  get permissionMenu() {
    return this.tenant
      ? [
          { text: '个人设置', value: '/user/setting' },
          { text: '在线反馈', value: '/feedback' },
          { text: '图片库', value: '/image' },
          { text: '我的组织', value: '/user/organization' },
          { text: '企业知识库', value: '/knowledge' },
        ]
      : [{ text: '个人设置', value: '/user/setting' }];
  }
  get userMenu() {
    return [...this.permissionMenu, { text: '退出', value: 'logout' }];
  }
  handleSkinChange(value) {
    this.$store.commit('crossStorage/setItem', {
      key: 'skin',
      value: JSON.stringify(value),
    });
  }
  @Watch('skin')
  setTheme(value) {
    ThemeSelect.setTheme(document.documentElement, value);
  }

  handleRoute(path) {
    if (this.tenant) {
      window.open(createModuleUrl('app') + path, '_blank');
    } else {
      this.handleShowOperationSelect();
    }
  }
  handleShowOperationSelect() {
    const model = createModal(
      () => (
        <XOperationSelect
          onRefreshData={() => {
            this.refreshCompanyList();
            model.handleClosed();
          }}
        />
      ),
      {
        title: '选择操作',
        width: '730px',
        className: 'x-operation-select-modal',
      },
    );
  }

  get localeList() {
    return [
      { text: '简体中文', value: 'zh_CN' },
      { text: '繁体中文', value: 'zh_TW' },
      { text: 'ENGLISH', value: 'en_US' },
    ];
  }
  @crossStorageModule.State('locale') locale;
  handleLocaleChange(value) {
    this.$store.commit('crossStorage/setItem', {
      key: 'locale',
      value,
    });
  }
  @Watch('locale')
  setLocale(value) {
    globalVueI18n.locale(value);
  }

  handleUserMenuClick({ key }) {
    switch (key) {
      case 'logout':
        ssoClient.logout();
        break;
      default:
        window.open(createModuleUrl('app') + key, '_blank');
        break;
    }
  }

  handleMessageClick(message) {
    switch (message.msgEvent) {
      case 'triascloud-workflow':
        if (
          message.detail &&
          message.detail.activityId &&
          message.detail.dataId &&
          message.detail.formId
        ) {
          const { activityId, dataId, formId } = message.detail;
          return this.handleLinkTarget(
            `/todo/my?activityId=${activityId}&dataId=${dataId}&formId=${formId}`,
          );
        }
        break;
      case 'applyForMember':
        return this.handleLinkTarget('/company/book');
      case 'addFeedbackMsg':
      case 'reviewFeedbackMsg':
        return this.handleLinkTarget(`/feedback?pkId=${message.redirectId}`);
      default:
        break;
    }
    switch (message.msgType) {
      case 'SYSTEM':
        return this.handleLinkTarget('/message/log');
      case 'APPLICATION':
        if (
          message.msgEvent === 'repository-comment-event' ||
          message.msgEvent === 'repository-remind-event'
        ) {
          const { repositoryId, documentId } = message.detail;
          return this.handleLinkTarget(
            `/knowledge/${repositoryId}/${documentId}`,
          );
        }
        return this.handleLinkTarget('/message/application');
      case 'TASK':
        return this.handleLinkTarget('/message/task');
      default:
        return this.handleLinkTarget('/message');
    }
  }
  @Watch('refreshMessageFlag')
  handleRefreshMessage() {
    this.$refs.notice.getMessageCount();
  }
  /** @name 跳转到三叠云 */
  handleLinkTarget(link) {
    return window.open(createModuleUrl('app') + link, '_blank');
  }
}
</script>
<style lang="less" module>
.globalHeaderRight {
  display: flex;
  :global(.ant-badge-count) {
    padding: 0;
    min-width: 18px;
    min-height: 18px;
    line-height: 18px;
  }
  :global(.ant-badge-count),
  :global(.ant-badge-dot),
  :global(.ant-badge .ant-scroll-number-custom-component) {
    top: 6px;
    left: 9px;
  }
}
.iconLink {
  margin-right: 4px;
  cursor: pointer;
  :global(.anticon) {
    width: 56px;
    height: 56px;
    color: var(--font);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :hover {
    color: var(--primary);
  }
}
</style>
