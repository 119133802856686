import Vue from 'vue';
import { globalVueI18n } from '@triascloud/i18n';
import dayjs from 'dayjs';
import {
  crossStorage,
  CrossStorageEventType,
  CrossStorageWeb,
} from '@triascloud/cross-storage';
import { delay } from '@triascloud/utils';
import { SSOClient, ssoClient, SSOClientWeb } from '@triascloud/sso-client';
import { ExpiredLogin, PathHelper } from '@triascloud/x-blocks';
import { globalSocket } from '@triascloud/message-hub';
import '@triascloud/ant-design-theme/dist/index.css';
import '@triascloud/x-components/dist/index.css';
import '@triascloud/x-blocks/dist/index.css';
import '@/assets/css/main.less';
import './hooks.js';
import './errorHander.js';
import App from './App.vue';
import router from './router';
import store from './store';
import logger from './utils/logger';
import { createModuleUrl } from './utils';
import install from './install';
// import TranslatePath from './utils/translate-path';
import { getRoutes, findMatchRouteByTargetlist } from '@/assets/js/util.js';
import moment from 'moment';
import '@/assets/css/screen-theme.less';
import { CrossStorageMemory } from '@/utils/CrossStorageMemory.js';
import { checkHost } from '@/utils/custom-host.js';
import { getCurrentInfoByToken } from '@/services/template-manager/website';

async function bootstrap() {
  // 自定义域名
  const isCustomHost = await checkHost('ds');
  Vue.prototype.dayjs = dayjs;
  Vue.prototype.$moment = moment;
  Vue.use(install);
  Vue.use(PathHelper);
  const routes = getRoutes(router.options.routes);
  const matchPath = findMatchRouteByTargetlist(
    routes,
    window.location.pathname,
    'share',
  );
  // 初始化单点登录环境
  const ssoOrigin = isCustomHost
    ? createModuleUrl('app')
    : createModuleUrl('account');
  // const ssoOrigin = 'http://localhost:8000/';
  ssoClient.init(
    new SSOClientWeb({
      serverPath: isCustomHost ? () => '/login.html' : undefined,
      server: ssoOrigin,
      requestExpiredLogin: () =>
        ExpiredLogin.create(
          isCustomHost
            ? `${ssoOrigin}/login.html?${
                SSOClient.TOKEN_KEY
              }=${ssoClient.getTokenSync()}&to=${encodeURIComponent(
                location.href,
              )}`
            : ssoOrigin,
        ),
    }),
  );
  // 初始化跨域储存环境
  const data = await crossStorage.init(
    isCustomHost && !isCustomHost.isSameTopLevelDomain
      ? new CrossStorageMemory({
          loadExtraStorage: token => getCurrentInfoByToken(token),
        })
      : new CrossStorageWeb(`${ssoOrigin}/storage.html`),
  );

  // 初始化国际化
  Vue.use(globalVueI18n, {
    defaultLocale: data.locale || 'zh_CN',
    defaultModule: 'ds',
    // renderEmpty: key => key,
  });
  if (!matchPath) {
    // 要求登录后才能访问
    await ssoClient.login();

    const token = await ssoClient.getToken();
    // websocket链接
    globalSocket.init(
      `${createModuleUrl('api', 'https:')}?X-Access-Token=${token}`,
    );
    // 监听token变化重连
    crossStorage.serverEmitter.on(
      CrossStorageEventType.Update,
      ({ key, value }) => {
        switch (key) {
          case SSOClient.TOKEN_KEY: {
            globalSocket.close();
            if (!value) return;
            globalSocket.init(
              `${createModuleUrl('api', 'https:')}?X-Access-Token=${value}`,
            );
          }
        }
      },
    );

    // 公司切换，socket重连
    store.watch(
      ({ crossStorage: { tenant = {} } }) => tenant && tenant.pkId,
      async () => {
        globalSocket.close();
        // 延迟连接，不然清除不了上一个连接
        await delay(0);
        globalSocket.connect();
      },
    );
    store.watch(
      ({ crossStorage: { user = {} } }) => user.pkId,
      uid => logger.setConfig({ uid }),
      {
        immediate: true,
      },
    );
  }

  // 初始化Vue
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
}

bootstrap();
