<template>
  <div :class="$style.card">
    <slot name="headerContent">
      <div v-if="showHeader" :class="$style.header">
        <div :class="$style.headerContent">
          <slot name="header">
            <div :class="[$style.title, 'flex']" v-if="Array.isArray(title)">
              <div
                @click="goTo(titleIndex)"
                :class="[
                  $style.titleItem,
                  currentIndex === titleIndex ? $style.titleItemActive : '',
                ]"
                v-for="(item, titleIndex) in title"
                :key="titleIndex"
              >
                {{ item }}{{ filterTitleLine(titleIndex) }}
              </div>
              <slot name="titleExtend"></slot>
            </div>
            <div :class="$style.title" v-else>
              {{ title }}
              <slot name="titleExtend"></slot>
            </div>
            <slot name="action" :index="currentIndex" />
          </slot>
        </div>
      </div>
    </slot>
    <div :class="$style.content" ref="contentRef">
      <a-carousel
        :dots="false"
        ref="carouselRef"
        class="h-full overflow-hidden"
        :beforeChange="beforeChange"
        v-if="Array.isArray(title) && showHeader"
      >
        <slot :index="currentIndex" />
      </a-carousel>
      <slot v-else></slot>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon/index.vue';

@Component({
  components: { AliIcon },
})
export default class LayoutCard extends Vue {
  @Prop({ type: [String, Array] }) title;
  @Prop({ type: Boolean, default: true }) showHeader;

  currentIndex = 0;

  goTo(index) {
    if (this.$refs.carouselRef) {
      this.$refs.carouselRef.goTo(index);
    }
    this.$emit('changeTitle', index);
  }

  beforeChange(form, to) {
    this.currentIndex = to;
  }

  filterTitleLine(index) {
    return index + 1 < this.title.length ? '/' : '';
  }
}
</script>
<style module lang="less">
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 10;
  overflow: hidden;
  background: var(--screen-box-bg);
  box-shadow: var(--screen-box-shadow);
  border: 1px solid var(--screen-box-border-color);
  border-radius: 0.04rem;
  .header {
    height: 0.34rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.08rem;
    .headerContent {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.12rem 0 0.16rem;
    }
    .title {
      flex: 1;
      font-size: 0.17rem;
      color: var(--screen-font-3);
      display: flex;
      align-items: center;
      white-space: nowrap;
      height: 100%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    :global {
      .x-empty-content--image {
        background: url(~@/assets/images/theme/empty.png) center / auto 1rem
          no-repeat;
      }
      .x-empty-content--label {
        color: #9ac0d9;
        font-size: 0.14rem;
      }
    }
  }
}
.titleItem {
  cursor: pointer;
  opacity: 0.5;
}
.titleItemActive {
  opacity: 1;
}
:global(.ant-carousel) {
  :global(.slick-slider) {
    height: 100%;
  }

  :global(.slick-list) {
    height: 100%;
  }

  & + :global(div) {
    height: 100%;
  }

  :global(.slick-track) {
    height: 100%;
  }

  :global(.slick-slide) {
    height: 100%;
    :global(div:first-child) {
      height: 96%;
    }
  }
}
</style>
