import ProjectIndex from '@/views/screen/project/index.vue';

/**
 * 江苏省标准
 */
const JIANGSU = [
  {
    path: 'screen-map',
    component: () => import('@/views/screen/project/screen-map'),
  },
  {
    path: 'overview',
    component: () => import('@/views/screen/project/project-overview'),
  },
  {
    path: 'video-monitor',
    component: () => import('@/views/screen/project/video-monitor'),
  },
  {
    path: 'people-management',
    component: () => import('@/views/screen/project/people-management'),
  },
  {
    path: 'car-management',
    component: () => import('@/views/screen/project/car-management'),
  },
  {
    path: 'green-construction',
    component: () => import('@/views/screen/project/green-construction'),
  },
  {
    path: 'safety/tower',
    component: () => import('@/views/screen/project/safety/tower'),
  },
  {
    path: 'safety/lift',
    component: () => import('@/views/screen/project/safety/lift'),
  },
  {
    path: 'safety/unload',
    component: () => import('@/views/screen/project/safety/unload'),
  },
  {
    path: 'safety/pit',
    component: () => import('@/views/screen/project/safety/pit'),
  },
  {
    path: 'safety/formwork',
    component: () => import('@/views/screen/project/safety/formwork'),
  },
  {
    path: 'safety/protection',
    component: () => import('@/views/screen/project/safety/protection'),
  },
  {
    path: 'spot-checking',
    component: () => import('@/views/screen/project/spot-checking'),
  },
  {
    path: 'intelligent-weighbridge',
    component: () => import('@/views/screen/project/intelligent-weighbridge'),
  },
  {
    path: 'water-electricity',
    component: () => import('@/views/screen/project/water-electricity'),
  },
  {
    path: 'ai-recognition',
    component: () => import('@/views/screen/project/ai-recognition'),
  },
  {
    path: 'progress-control',
    component: () => import('@/views/screen/project/progress-control'),
  },
  {
    path: 'custom-link',
    component: () => import('@/views/screen/project/custom-link'),
  },
];

/**
 * BI大屏路由
 */
const screen = [
  /** @name 大屏页面 */
  {
    path: '/screen',
    name: 'screen',
    component: ProjectIndex,
    meta: {
      auth: false,
    },
  },
  {
    path: '/project',
    name: 'project',
    component: ProjectIndex,
    meta: {
      auth: false,
    },
    children: [
      {
        path: '403',
        component: () => import('@/views/screen/403.vue'),
      },
      ...JIANGSU,
    ],
  },
];

export default screen;
