<template>
  <div class="x-layout-tabs">
    <LayoutButton
      v-for="item in options"
      :key="item.key"
      :active="innerValue === item.key"
      :size="size"
      @click="handleClick(item.key)"
      >{{ item.text }}</LayoutButton
    >
  </div>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LayoutButton from './layout-button.vue';

@Component({
  components: { LayoutButton },
})
export default class LayoutTabs extends Vue {
  @Prop() size;
  @Prop() value;
  @Prop() defaultValue;
  @Prop({ type: Array }) options;
  @Watch('value')
  updateValue(value) {
    value !== this.innerValue && (this.innerValue = value);
  }
  handleClick(key) {
    this.innerValue = key;
    this.$emit('input', key);
    this.$emit('change', key);
  }
  innerValue = null;
  created() {
    this.innerValue = this.value !== undefined ? this.value : this.defaultValue;
  }
}
</script>
<style lang="less">
.x-layout-tabs {
  & + * {
    margin-left: 0.05rem;
  }
}
</style>
