import request from '../request';

const serviceName = '/smart-site/screen';

// 获取项目统计
export function projectStatistics(data) {
  return request(`${serviceName}/project/statistics`, {
    method: 'GET',
    body: data,
  });
}
// 获取在建项目统计排行
export function projectConstruction(data) {
  return request(`${serviceName}/project/region-top`, {
    method: 'GET',
    body: data,
  });
}
// 警报最新动态-v1
export function getAlarmList(data) {
  return request(`${serviceName}/alarm/latest/list/v1`, {
    method: 'POST',
    body: data,
  });
}
// 项目警报统计（图表）
export function getProjectAlarmStatistics(data) {
  return request(`${serviceName}/alarm/project`, {
    method: 'POST',
    body: data,
  });
}
// 获取项区域统计
export function areaMap(data) {
  return request(`${serviceName}/region/statistics`, {
    method: 'GET',
    body: data,
  });
}
// 获取地图项目列表
export function projectMap(data) {
  return request(`${serviceName}/project/map`, {
    method: 'GET',
    body: data,
  });
}
// 获取搜索项目列表
export function projectList(data) {
  return request(`${serviceName}/project/listBySearch`, {
    method: 'GET',
    body: data,
  });
}
// 获取城市级别
export function cityList(data) {
  return request('/configuration/regionInfo/searchSite', {
    method: 'GET',
    body: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
// 获取出勤人数排行
export function attendanceList(data) {
  return request(`${serviceName}/personnel/attendanceOfEachProvince`, {
    method: 'GET',
    body: data,
  });
}

export function projectAttendanceList(data) {
  return request(`${serviceName}/attendanceOfEachProject`, {
    method: 'GET',
    body: data,
  });
}

/** @name 生成分享链接 */
export function shareGenerate(data) {
  return request(`${serviceName}/share/generate`, {
    method: 'POST',
    body: data,
  });
}

// 获取项目信息
export function projectInfo(projectId) {
  return request(`${serviceName}/overview/project/overview/info/${projectId}`, {
    method: 'GET',
  });
}

// 根据租户id获取导航组织组织树
export function getOrganizationTreeList() {
  return request(`${serviceName}/getOrganizationTreeList`, {
    method: 'GET',
  });
}

// 获取大屏菜单列表
export function getScreenMenuList(data) {
  return request(`${serviceName}/navigate/menu/getAllMenuList`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 获取设备分布情况
 * @returns Promise<[]>
 */
export function getDeviceCount(data) {
  return request(`${serviceName}/deviceCount`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 统计成员
 * @returns Promise<[]>
 */
export function getCountMember(data) {
  return request(`${serviceName}/countMember`, {
    method: 'GET',
    body: data,
  });
}
