import Vue from 'vue';
import logger from './utils/logger';

/** @name custom error */
const CUSTOM_ERROR_KEYS = [
  'user cancel', // 弹窗被取消
  'form validate error', // 表单验证失败时
  'No ElectricBox', // 楼栋没有绑定安监终端
];
function hasCustomError(message) {
  return CUSTOM_ERROR_KEYS.find(item => item === message);
}

window.addEventListener('unhandledrejection', ev => {
  if (isExpectedRejection(ev.reason)) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
  }
});

export function isExpectedRejection(reason) {
  // ant-design-vue的form validate不通过时抛出false错误
  if (reason === false) return true;
  const message =
    typeof reason === 'string' ? reason : reason && reason.message;
  // 自定义错误
  if (hasCustomError(message)) return true;
  return false;
}

// 屏蔽掉用户关闭弹窗的报错
Vue.config.errorHandler = err => {
  if (isExpectedRejection(err)) return;
  logger.error(err);
  throw err;
};

// 解决打包后组件名被压缩的问题
if (process.env.NODE_ENV === 'production') {
  Vue.config.warnHandler = msg => {
    if (
      msg &&
      msg.startsWith(
        'Do not use built-in or reserved HTML elements as component id',
      )
    )
      return;
    // eslint-disable-next-line no-console
  };
}
